import Icon from "Common/UI/Component/Icon";
import { PaddedContainer } from "Common/UI/Layout/Container";

export default function Page() {
    return (
        <PaddedContainer className="min-h-screen bg-white">
            <div className="flex h-screen items-center justify-center">
                <section className="h-4/5">
                    <section className="flex flex-col items-center mb-32">
                        <div className="border-b-2 w-6 border-primary"></div>
                        <h2 className="text-4xl tracking-tight font-extrabold text-primary sm:text-5xl md:text-6xl">
                            Layanan
                        </h2>
                    </section>

                    <nav className="grid grid-cols-3 gap-24 mx-32 justify-center">
                        <a href="https://form.mitrasdudi.id/fill/2" target="_blank" className="flex flex-col items-center hover:animate-pulse" rel="noreferrer">
                            <div className="bg-[#1c2855] rounded-full items-center justify-center flex">
                                <Icon.IconPhospor.Lifebuoy className="h-52 w-auto text-white m-3" />
                            </div>
                            <h2 className="text-2xl tracking-tight font-extrabold sm:text-3xl md:text-4xl text-[#1c2855] text-center">
                                Pengaduan Masyarakat & Layanan Publik
                            </h2>
                            <div className="border-b-2 w-6  border-[#1c2855]"></div>
                        </a>
                        <a href="https://wbs.mitrasdudi.id/" target="_blank" className="flex flex-col items-center hover:animate-pulse" rel="noreferrer">
                            <div className="bg-[#219ba8] rounded-full items-center justify-center flex">
                                <Icon.IconPhospor.Envelope className="h-52 w-auto text-white m-3 p-3" />
                            </div>
                            <h2 className="text-2xl tracking-tight font-extrabold sm:text-3xl md:text-4xl text-[#219ba8] text-center">
                                Whistle Blowing System (WBS)
                            </h2>
                            <div className="border-b-2 w-6  border-[#219ba8]"></div>
                        </a>
                        <a href="https://www.lapor.go.id/" target="_blank" className="flex flex-col items-center hover:animate-pulse" rel="noreferrer">
                            <div className="bg-[#1c2855] rounded-full items-center justify-center flex">
                                <Icon.IconPhospor.Megaphone className="h-52 w-auto text-white m-3 p-3" />
                            </div>
                            <h2 className="text-2xl tracking-tight font-extrabold sm:text-3xl md:text-4xl text-[#1c2855] text-center">
                                SP4N LAPOR
                            </h2>
                            <div className="border-b-2 w-6  border-[#1c2855]"></div>
                        </a>
                    </nav>
                </section>
            </div>
        </PaddedContainer>
    )
}