import { Fragment } from "react";
import NavigationBar from "./NavigationBar";
import Footer from "./Footer";

export default function MainLayout({ children }) {

    return (
        <main className="relative">
            <NavigationBar />

            <article className="min-h-screen">
                {children}
            </article>

            <Footer />
        </main>
    )
}