

export function statusColor(status) {
    return ({
        success: 'bg-green-100 text-green-800',
        processing: 'bg-yellow-100 text-yellow-800',
        failed: 'bg-rose-100 text-rose-800',
    }[status] || 'bg-gray-100 text-gray-800')
}

export function statusColorForFactoryDemand(factoryDemand) {
    return statusColor(
        factoryDemand.state.cancel ? 'failed' :
            factoryDemand.state.finish ? 'success' :
                'processing'
    )
}


export function intToRGB(i){
    var c = (i & 0x00FFFFFF)
        .toString(16)
        .toUpperCase();

    return "00000".substring(0, 6 - c.length) + c;
}