import MainLayout from "Common/UI/Layout/Main/MainLayout";
import { useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import HomePage from "./Route";
import PhotoStoryPage from "./Route/photo-story";
import PublicInformationPage from "./Route/informasi-publik";
import ReportPage from "./Route/pengaduan";
import DetailPublicInformationPage from "./Route/informasi-publik/[slug]";
import ServicePage from "./Route/layanan";
import ProfilePage from "./Route/profil";
import EventPage from "./Route/agenda";
import Error404 from "./Route/Error404";
import DetailArticlePage from "./Route/article/[id]";

export default function GuestRouters() {
    const location = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location.pathname]);

    return (
        <MainLayout>
            <TransitionGroup component={null}>
                <CSSTransition key={location.key} classNames="fade" timeout={300}>
                    <Routes location={location}>
                        <Route path="/" element={<HomePage />} />
                        <Route path="/photo-story" element={<PhotoStoryPage />} />
                        <Route path="/photo-story/:id" element={<PhotoStoryPage />} />
                        <Route path="/informasi-publik" element={<PublicInformationPage />} />
                        <Route path="/informasi-publik/:slug" element={<DetailPublicInformationPage />} />
                        <Route path="/artikel/:id" element={<DetailArticlePage />} />
                        <Route path="/layanan" element={<ServicePage />} />
                        <Route path="/pengaduan" element={<ReportPage />} />
                        <Route path="/profil" element={<ProfilePage />} />
                        <Route path="/agenda" element={<EventPage />} />
                        <Route path="*" element={<Error404 />} />
                    </Routes>
                </CSSTransition>
            </TransitionGroup>
        </MainLayout >
    )
}
