import { Typography } from "@material-tailwind/react";
import { Fragment } from "react";

import { useParams } from "react-router-dom";
import { PaddedContainer } from "Common/UI/Layout/Container";
import { StickySection } from "Common/UI/Block/Section";
import ReactPlayer from 'react-player/file'
import './player.css'

export default function Page() {
    const { id } = useParams()
    return (
        <PaddedContainer className="min-h-screen bg-white pt-10 pb-24">
            <div className="flex flex-col items-start  justify-center">
                <div className="flex justify-center w-full mt-10">
                    <div className="">
                        <ReactPlayer
                            url={'/files/video/profile.mp4'}
                            playing={true}
                            loop={true}
                            width="100%"
                            height="100%"
                            controls={true}
                        />
                    </div>
                </div>
                <StickySection title="Bekerja Bersama, Menjadi Bagian dari Pembangunan Masa Depan">
                    <Fragment>
                        <figure className="relative h-auto w-full hidden">
                            <img
                                className="h-full w-full rounded-xl object-cover object-center"
                                src={require('../../../Asset/Dummy/ZI-WBK/@Herdwiprapto-3934-min.JPG')}
                                alt="nature image"
                            />
                            <figcaption className="absolute bottom-8 left-2/4 flex w-[calc(100%-4rem)] -translate-x-2/4 justify-between rounded-xl border border-white bg-white/75 py-4 px-6 shadow-lg shadow-black/5 saturate-200 backdrop-blur-sm">
                                <div className="text-left">
                                    <Typography variant="h5" color="blue-gray">
                                        Dokumentasi Pencanangan
                                    </Typography>
                                    <Typography color="gray" className="mt-2 font-normal">
                                        31 Januari 2023
                                    </Typography>
                                </div>
                                <Typography variant="h5" color="blue-gray">
                                    Dokumentasi
                                </Typography>
                            </figcaption>
                        </figure>
                        <div className="flex flex-col justify-center w-4/5 text-center gap-10 my-10">
                            <p className="tracking-tight font-medium font-[Lora] leading-relaxed text-xl">
                                Direktorat Kemitraan dan Penyelarasan Dunia Usaha dan Dunia Industri atau lebih populer dengan nama Direktorat Mitras DUDI merupakan unit organisasi Direktorat Jenderal Pendidikan Vokasi yang berdiri sejak tahun 2019 berdasarkan Permendikbud Nomor 45 Tahun 2019 tentang Organisasi dan Tata Kerja Kementerian Pendidikan dan Kebudayaan sebagaimana telah diubah dengan Permendikbud Nomor 9 Tahun 2020. Sebagai satuan kerja, Direktorat Mitras DUDI bertugas melaksanakan perumusan dan pelaksanaan kebijakan, penyusunan norma, standar, prosedur, dan kriteria, fasilitasi, pemberian bimbingan teknis dan supervisi, pemantauan, evaluasi, dan pelaporan di bidang kemitraan dan penyelarasan dunia usaha dan dunia industri (DUDI) dengan sekolah menengah kejuruan (SMK), pendidikan tinggi vokasi dan profesi (PTVP), dan pendidikan keterampilan dan pelatihan kerja, serta urusan ketatausahaan Direktorat.
                            </p>
                            <p className="tracking-tight font-medium font-[Lora] leading-relaxed text-xl">
                                Direktorat Kemitraan dan Penyelarasan Dunia Usaha dan Dunia Industri mempunyai tugas
                                melaksanakan perumusan dan pelaksanaan kebijakan, penyusunan norma, standar, prosedur,
                                dan kriteria, fasilitasi, pemberian bimbingan teknis dan supervisi, pemantauan, evaluasi, dan
                                pelaporan di bidang kemitraan dan penyelarasan dunia usaha dan dunia industri dengan SMK,
                                pendidikan tinggi vokasi dan profesi, dan pendidikan keterampilan dan pelatihan kerja.
                            </p>
                        </div>
                    </Fragment>
                </StickySection>
                <StickySection title="Tugas dan Fungsi">
                    <Fragment>
                        <figure className="relative h-auto w-full hidden">
                            <img
                                className="h-full w-full rounded-xl object-cover object-center"
                                src={require('../../../Asset/Dummy/ZI-WBK/@Herdwiprapto-3934-min.JPG')}
                                alt="nature image"
                            />
                            <figcaption className="absolute bottom-8 left-2/4 flex w-[calc(100%-4rem)] -translate-x-2/4 justify-between rounded-xl border border-white bg-white/75 py-4 px-6 shadow-lg shadow-black/5 saturate-200 backdrop-blur-sm">
                                <div className="text-left">
                                    <Typography variant="h5" color="blue-gray">
                                        Dokumentasi Pencanangan
                                    </Typography>
                                    <Typography color="gray" className="mt-2 font-normal">
                                        31 Januari 2023
                                    </Typography>
                                </div>
                                <Typography variant="h5" color="blue-gray">
                                    Dokumentasi
                                </Typography>
                            </figcaption>
                        </figure>
                        <div className="flex flex-col gap-4 my-6">
                            <section className="">
                                <h3 className="text-md tracking-tight font-extrabold text-primary sm:text-md md:text-3xl mb-5">Tugas</h3>
                                <p className="tracking-tight font-medium font-[Lora] leading-relaxed text-xl">
                                    Tugas
                                    Direktorat Kemitraan dan Penyelarasan Dunia Usaha dan Dunia Industri mempunyai tugas
                                    melaksanakan perumusan dan pelaksanaan kebijakan di bidang kemitraan dan
                                    penyelarasan dunia usaha dan dunia industri
                                </p>
                            </section>
                            <section className="">
                                <h3 className="text-md tracking-tight font-extrabold text-primary sm:text-md md:text-3xl mb-5">Fungsi</h3>
                                <ol className="list-decimal flex flex-col gap-2 mt-5 ml-10">
                                    <li className="tracking-tight font-medium font-[Lora] leading-relaxed text-xl">
                                        Dalam melaksanakan tugasnya, Direktorat Kemitraan dan Penyelarasan Dunia Usaha dan
                                        Dunia Industri menyelenggarakan fungsi: penyiapan perumusan kebijakan di bidang
                                        kemitraan dan penyelarasan dunia usaha dan dunia industri dengan sekolah menengah
                                        kejuruan, pendidikan tinggi vokasi dan profesi, dan pendidikan keterampilan dan pelatihan
                                        kerja;
                                    </li>
                                    <li className="tracking-tight font-medium font-[Lora] leading-relaxed text-xl">
                                        Pelaksanaan kebijakan di bidang kemitraan dan penyelarasan dunia usaha dan dunia
                                        industri dengan sekolah menengah kejuruan, pendidikan tinggi vokasi dan profesi, dan
                                        pendidikan keterampilan dan pelatihan kerja
                                    </li>
                                    <li className="tracking-tight font-medium font-[Lora] leading-relaxed text-xl">
                                        Fasilitasi di bidang kemitraan dan penyelarasan dunia usaha dan dunia industri dengan
                                        sekolah menegah kejuruan, pendidikan tinggi vokasi dan profesi, dan pendidikan
                                        keterampilan dan pelatihan kerja;
                                    </li>
                                    <li className="tracking-tight font-medium font-[Lora] leading-relaxed text-xl">
                                        Pemberian bimbingan teknis dan supervisi di bidang kemitraan dan penyelarasan dunia
                                        usaha dan dunia industri dengan sekolah menengah kejuruan, pendidikan tinggi vokasi dan
                                        profesi, dan pendidikan keterampilan dan pelatihan kerja;
                                    </li>
                                    <li className="tracking-tight font-medium font-[Lora] leading-relaxed text-xl">
                                        Pemantauan, evaluasi, dan pelaporan di bidang kemitraan dan penyelarasan dunia usaha
                                        dan dunia industri; dan
                                    </li>
                                    <li className="tracking-tight font-medium font-[Lora] leading-relaxed text-xl">
                                        Pelaksanaan urusan ketatausahaan Direktorat.
                                    </li>
                                </ol>
                            </section>
                            <section className="">
                                <h3 className="text-md tracking-tight font-extrabold text-primary sm:text-md md:text-3xl mb-5">Direktorat Kemitraan dan Penyelarasan Dunia Usaha dan Dunia Industri terdiri atas</h3>
                                <ol className="list-decimal flex flex-col gap-2 mt-5 ml-10">
                                    <li className="tracking-tight font-medium font-[Lora] leading-relaxed text-xl">
                                        Subbagian Tata Usaha; dan
                                    </li>
                                    <li className="tracking-tight font-medium font-[Lora] leading-relaxed text-xl">
                                        Kelompok Jabatan Fungsional.
                                    </li>

                                </ol>
                                <p className="mt-5 tracking-tight font-medium font-[Lora] leading-relaxed text-xl">
                                    Subbagian Tata Usaha mempunyai tugas melakukan urusan perencanaan, keuangan,
                                    kepegawaian, ketatalaksanaan, barang milik negara, persuratan, kearsipan, dan
                                    kerumahtanggaan Direktorat.
                                </p>
                            </section>
                        </div>
                    </Fragment>
                </StickySection>
                <StickySection title="Struktur Organisasi">
                    <Fragment>
                        <figure className="relative h-auto w-full hidden">
                            <img
                                className="h-full w-full rounded-xl object-cover object-center"
                                src={require('../../../Asset/Dummy/ZI-WBK/@Herdwiprapto-3934-min.JPG')}
                                alt="nature image"
                            />
                            <figcaption className="absolute bottom-8 left-2/4 flex w-[calc(100%-4rem)] -translate-x-2/4 justify-between rounded-xl border border-white bg-white/75 py-4 px-6 shadow-lg shadow-black/5 saturate-200 backdrop-blur-sm">
                                <div className="text-left">
                                    <Typography variant="h5" color="blue-gray">
                                        Dokumentasi Pencanangan
                                    </Typography>
                                    <Typography color="gray" className="mt-2 font-normal">
                                        31 Januari 2023
                                    </Typography>
                                </div>
                                <Typography variant="h5" color="blue-gray">
                                    Dokumentasi
                                </Typography>
                            </figcaption>
                        </figure>
                        <div className="flex flex-col  my-6">
                            <section className="">
                                <p className="tracking-tight font-medium font-[Lora] leading-relaxed text-xl">
                                    Direktorat Kemitraan dan Penyelarasan Dunia Usaha dan Dunia Industri mempunyai tugas
                                    melaksanakan perumusan dan pelaksanaan kebijakan di bidang kemitraan dan penyelarasan
                                    dunia usaha dan dunia industri
                                </p>
                            </section>
                            <img src={require('../../../Asset/Dummy/Struktur MitrasDUDI 2024.png')} />
                            <img src={require('../../../Asset/Dummy/Struktur MitrasDUDI 2024 (3).png')} />
                            <img src={require('../../../Asset/Dummy/budaya kerja mitrasdudi.png')} className="my-20" />

                        </div>
                    </Fragment>
                </StickySection>
            </div>
        </PaddedContainer>
    )
}