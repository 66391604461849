import { requestGet } from "Common/Provider/RestClient"
import { useQuery } from "@tanstack/react-query"

export function useCalendarAttendance({ onSuccess, startDate, endDate, properties = [], type = null }) {
    const path = `/human-resource/attendance/attendance/calendar`
    const data = useQuery({
        queryKey: [path, type, startDate, endDate, properties.join(',')],
        queryFn: () => requestGet(path, { startDate, endDate, properties: properties.join(','), }),
        onSuccess: (response) => onSuccess ? onSuccess(response) : null,
    })
    return data
}
