import { Avatar, Card, CardBody, CardHeader, Typography } from "@material-tailwind/react";
import Icon from "Common/UI/Component/Icon";
import React from "react";
import { Link } from "react-router-dom";

export const CardVocationStory = React.memo(function (props) {
    const { data, dataIndex } = props;
    const { featuredImage, title, shortSummary, link } = data[dataIndex];

    return (
        <a href={link}>
            <Card
                shadow={true}
                className="relative grid h-[40rem] w-full max-w-[28rem] items-end justify-center overflow-hidden text-center group cursor-pointer"
            >
                <CardHeader
                    floated={true}
                    shadow={true}
                    color="transparent"
                    className={`absolute inset-0 m-0 h-full w-full rounded-none bg-[url('')] bg-cover bg-center`}
                >
                    <img src={featuredImage.url} alt={featuredImage.alt} className={`absolute inset-0 m-0 h-full w-full rounded-none object-cover group-hover:transition group-hover:duration-500 group-hover:scale-125`} />
                    <div className="to-bg-black-10 absolute inset-0 h-full w-full bg-gradient-to-t from-black/80 via-black/50" />
                </CardHeader>
                <CardBody className="relative py-1 px-6 md:px-12 text-left">
                    <Typography
                        variant="h5"
                        color="white"
                        className="mb-6 font-medium leading-[1.5]"
                    >
                        {title}
                    </Typography>
                    <Typography variant="paragraph" className="mb-4 text-gray-400 font-extralight hidden sm:block transition h-0 translate-y-[400px] opacity-0 group-hover:translate-y-0 group-hover:opacity-100 ease-in-out duration-75 group-hover:animate-pulse group-hover:h-fit" textGradient={true}>
                        {shortSummary}
                    </Typography>
                </CardBody>
            </Card>
        </a>
    )
});

export const CardPhotoStory = React.memo(function (props) {
    const { data, dataIndex } = props;
    const { id, image, title, description } = data[dataIndex];

    return (
        <Card
            shadow={true}
            className="relative grid h-[40rem] w-full max-w-[28rem] items-end justify-center overflow-hidden text-center group cursor-pointer"
        >
            <CardHeader
                floated={true}
                shadow={true}
                color="transparent"
                className={`absolute inset-0 m-0 h-full w-full rounded-none bg-[url('')] bg-cover bg-center`}
            >
                <Link to={`/photo-story/${id}`} className="text-white flex items-center gap-1.5 hover:font-extrabold">
                    <img src={image.url} alt={image.alt} className={`absolute inset-0 m-0 h-full w-full rounded-none object-cover group-hover:transition group-hover:duration-500 group-hover:scale-125`} />
                    <div className="to-bg-black-10 absolute inset-0 h-full w-full bg-gradient-to-t from-black/80 via-black/50" />
                </Link>
            </CardHeader>
            <CardBody className="absolute bottom-0 left-0 right-0 w-full p-0">
                <Link to={`/photo-story/${id}`} className="text-white flex items-center gap-1.5 hover:font-extrabold">
                    <div className="flex w-full px-2 pb-4">
                        <div className="flex-1">
                            <Typography
                                variant="h5"
                                color="white"
                                className=" font-bold leading-[1.5] text-left "
                            >
                                {title}
                            </Typography>
                        </div>
                        <div className="flex-1 flex justify-end items-center gap-2">
                            Read more...
                            <Icon.IconPhospor.CaretCircleRight />
                        </div>
                    </div>
                </Link>
            </CardBody>
        </Card>
    )
});