import { rawRequestGet} from "Common/Provider/RestClient"
import { useQuery } from "@tanstack/react-query"


export function useDetailPost(ID, { onSuccess, onError }) {
    const path = `https://blog.mitrasdudi.id/wp-json/wp/v2/posts/${ID}`
    const data = useQuery({
        queryKey: [path],
        queryFn: () => rawRequestGet(path, {}),
        onSuccess: (response) => onSuccess ? onSuccess(response) : null,
    })
    return data
}
