import { Avatar, Typography, avatar } from "@material-tailwind/react";
import { PaddedContainer } from "Common/UI/Layout/Container";
import { useDetailPost } from "Domain/Resource/Blog/API/PostAPI";
import axios from "axios";
import { Fragment, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import './post-contents.css'
import moment from "moment";
import ReactGA from "react-ga4";

export default function Page() {
    const [featuredImage, setFeaturedimage] = useState();
    const [author, setAuthor] = useState();
    const [category, setCategory] = useState();
    const { id } = useParams()
    const detailPost = useDetailPost(id, {})
    const post = detailPost.data?.data

    const getFeaturedImage = () => {
        if (post?._links["wp:featuredmedia"]) {
            axios
                .get(post?._links["wp:featuredmedia"][0]?.href)
                .then((response) => {
                    setFeaturedimage(response.data.source_url);
                });
        }
    };

    const getCategory = () => {
        if (post?._links["wp:term"]) {
            for (var i = 0; i < post?._links["wp:term"].length; i++) {
                if (post?._links["wp:term"][i].taxonomy === 'category') {
                    axios
                        .get(post?._links["wp:term"][i].href)
                        .then((response) => {
                            setCategory(response.data);
                        });
                }
            }

        }
    };

    const getAuthor = () => {
        if (post?._links["author"]) {
            axios
                .get(post?._links["author"][0]?.href)
                .then((response) => {
                    setAuthor(response.data);
                });
        }
    };

    useEffect(() => {
        if (post) {
            ReactGA.send({ hitType: "pageview", page: `/artikel/${id}`, title: post?.title.rendered });
        }
    }, [post])

    useEffect(() => {
        if (post) {
            getFeaturedImage()
            getAuthor()
            getCategory()
        }
    }, [post]);


    return (
        <article>
            {!featuredImage ?
                <div className="relative w-full flex items-end justify-center h-[370px] group mb-9 bg-gradient-to-b from-gray-500 to-white">
                    <div className="max-w-6xl text-center">
                        <Typography variant="h1" className="text-gray-900 font-[Lora]">
                            {post?.title.rendered}
                        </Typography>
                    </div>
                </div> :
                <section className="relative w-full h-[670px] group mb-9">
                    <img src={featuredImage} className={`absolute inset-0 m-0 h-full w-full rounded-none object-cover group-hover:transition group-hover:duration-500`} />
                    <div className="to-bg-black-10 absolute inset-0 h-full w-full bg-gradient-to-t from-black/80 via-black/50" />

                    <div className="absolute bottom-0 left-0 right-0 flex justify-center pb-10">
                        <div className="max-w-6xl">
                            <Typography variant="h1" className="text-white shadow-lg font-[Lora]">
                                {post?.title.rendered}
                            </Typography>
                        </div>

                    </div>
                </section>
            }


            <section className="flex justify-center w-full">
                <section className="max-w-7xl flex justify-between w-full">
                    <div>
                        {!author ? null :
                            <div className="flex gap-3">
                                <Avatar variant="circular" size="md" src={author.avatar_urls[24]} />
                                <dl className="font-[Montserrat] flex flex-col justify-center">
                                    <dt className="text-gray-600 uppercase text-sm">Author</dt>
                                    <dd className="capitalize text-sm font-bold">{author.name}</dd>
                                </dl>
                            </div>
                        }
                    </div>
                    <div>
                        <div className="flex gap-3 font-[Montserrat] font-semibold text-gray-600">
                            <span>{!post ? null : moment(post.modified_gmt).format('MMMM D, YYYY')}</span>
                            {!category ? null :
                                <Fragment>
                                    <span>&#183;</span>
                                    <span>{category[0].name}</span>
                                </Fragment>
                            }

                        </div>
                    </div>
                </section>

            </section>


            <div className="flex justify-center">
                <section className="max-w-7xl">
                    <p
                        className="post-contents"
                        dangerouslySetInnerHTML={{ __html: post?.content.rendered }}
                    />
                </section>
            </div>
        </article>
    )
}