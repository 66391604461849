import { rawRequestGet} from "Common/Provider/RestClient"
import { useQuery } from "@tanstack/react-query"


export function usePublishedHeroBanners({ onSuccess, onError }) {
    const path = `${process.env.REACT_APP_BASE_URL_PAYLOAD_API}/hero-banner`
    const data = useQuery({
        queryKey: [path],
        queryFn: () => rawRequestGet(path, {}),
        onSuccess: (response) => onSuccess ? onSuccess(response) : null,
    })
    return data
}
