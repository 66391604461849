import { Password } from 'primereact/password';
import { InputText } from 'primereact/inputtext';
import { InputNumber } from 'primereact/inputnumber';
import { InputTextarea } from "primereact/inputtextarea";
import { Calendar } from 'primereact/calendar';

const InputSecure = props => <Password feedback={false} inputClassName="w-full" {...props} />
const InputDate = props => <Calendar inputClassName="w-full" {...props} />

export { InputSecure, InputText, InputNumber, InputTextarea, InputDate }
export default { InputSecure, InputText, InputNumber, InputTextarea, InputDate }