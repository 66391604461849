


const CompanyLogo = (props) => (
    <img src={require('Asset/Logo/company-logo.png')} alt="Company Logo" {...props} />
)

const DepartmentLogo = (props) => (
    <img src={require('Asset/Logo/departement-logo.png')} alt="Department Logo" {...props} />
)
const CountryAnniversaryLogo = (props) => (
    <img src={require('Asset/Logo/country-anniversary-logo.png')} alt="HUT RI 78 Logo" {...props} />
)

export default { DepartmentLogo, CompanyLogo, CountryAnniversaryLogo }