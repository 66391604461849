const { default: Icon } = require("../Component/Icon");

const ErrorInput = ({ errors }) => !errors || !errors.length ? null : (
    <div className="rounded-md bg-red-50 p-4">
        <div className="flex">
            <div className="flex-shrink-0">
                <Icon.IconSolid.XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
            </div>
            <div className="ml-3">
                <h3 className="text-sm font-medium text-red-800">There {errors.length > 1 ? 'is' : 'are'} {errors.length} error(s) on your input</h3>
                <div className="mt-2 text-sm text-red-700">
                    <ul role="list" className="list-disc pl-5 space-y-1">
                        {errors.map(errorMesssage => <li>{errorMesssage}</li>)}
                    </ul>
                </div>
            </div>
        </div>
    </div>
)

export { ErrorInput }
export default { ErrorInput }