import React from 'react';
import ReactDOM from 'react-dom/client';
import { setLogger } from 'react-query'
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import momentLocaleID from 'moment/locale/id'
import moment from 'moment'

moment.updateLocale('id', momentLocaleID)
const root = ReactDOM.createRoot(document.getElementById('root'));

setLogger({
  log: console.log,
  warn: console.warn,
  error: () => { }, // do nothing
})

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
