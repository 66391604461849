import { rawRequestGet} from "Common/Provider/RestClient"
import { useQuery } from "@tanstack/react-query"

export function useFeaturedPartnerCompany({ onSuccess, onError }) {
    const path = `${process.env.REACT_APP_BASE_URL_PAYLOAD_API}/partner-company?depth=4`
    const data = useQuery({
        queryKey: [path],
        queryFn: () => rawRequestGet(path, {}),
        onSuccess: (response) => onSuccess ? onSuccess(response) : null,
    })
    return data
}
