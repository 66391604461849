import { Carousel, Typography, Button } from "@material-tailwind/react";
import { Fragment } from "react";

import { useParams } from "react-router-dom";
import { usePublishedPhotoStories } from "Domain/Content/PhotoStoryAPI";

export default function Page() {
    const { id } = useParams()
    const publishedPhotoStories = usePublishedPhotoStories({})
    const selector = (response) => response.data?.data?.docs || []
    const photoStories = selector(publishedPhotoStories)

    const displayFirst = photoStories.find(search => search.id === id)
    const displayPhotoStories = [...(displayFirst ? [displayFirst]: []), ...photoStories]
    return (
        <Fragment>
            <section>
                {publishedPhotoStories.isLoading || photoStories.length <= 0 ? null :
                    <Carousel className="rounded-xl" autoplay={true} loop={true} transition={{ type: "tween", duration: 0.5 }}>
                        {displayPhotoStories.map(photoStory =>
                            <div key={photoStory.id} className="relative h-screen w-full">
                                <img
                                    src={photoStory.image.url}
                                    alt={photoStory.image.alt}
                                    className="h-full w-full object-cover"
                                />
                                <div className="absolute inset-0 grid h-screen w-full items-end pb-10 bg-black/75">
                                    <div className="w-3/4 pl-12 md:w-3/4 md:pl-20 lg:pl-32 ">
                                        <Typography
                                            variant="h1"
                                            color="white"
                                            className="mb-4 text-3xl md:text-4xl lg:text-5xl"
                                        >
                                            {photoStory.title}
                                        </Typography>
                                        <Typography
                                            variant="lead"
                                            color="white"
                                            className="mb-12 opacity-80 hidden sm:block"
                                        >
                                            {photoStory.shortSummary}
                                        </Typography>
                                        <div className="flex gap-2">
                                            <Button size="lg" color="white">
                                                Baca
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </Carousel>
                }

            </section>
        </Fragment>
    )
}