import { create } from 'zustand'
import { persist, createJSONStorage } from 'zustand/middleware'

export const useVisitedStore = create(
    persist(
        (set, get) => ({
            isSeenBanner: false,
            flagAsSeenBanner: () => set({ isSeenBanner: true }),
        }),
        {
            name: 'seen-banner', // name of the item in the storage (must be unique)
            storage: createJSONStorage(() => localStorage), // (optional) by default, 'localStorage' is used
        }
    )
)