import Icon from "Common/UI/Component/Icon";
import Logo from "Common/UI/Component/Logo";
import classNames from "classnames";
import { Fragment } from "react";
import { NavLink } from "react-router-dom";


export default function Footer() {
    const navigationLinkClassName = ({ isActive }) => classNames(isActive ?
        "text-white font-bold flex gap-1.5" :
        "text-white font-light flex gap-1.5"
    )
    return (
        <footer className="bg-primary py-7">
            <section className="max-w-7xl mx-auto">
                <div className="mx-10 md:mx-0 flex  justify-between">
                    <section className="flex flex-col">
                        <div className="flex  w-6/12">
                            <Logo.CompanyLogo className="h-14 w-auto" />
                            <h3 className="text-white font-bold">
                                Kementerian Pendidikan, Kebudayaan, Riset, dan Teknologi RI
                                Direktorat Jenderal Pendidikan Vokasi
                            </h3>
                        </div>

                        <div className="grid grid-cols-1 md:grid-cols-2 ml-20 mt-3">
                            <div className="flex flex-col gap-1.5">
                                <div className="flex-row flex gap-1">
                                    <dt><Icon.IconPhospor.House className="w-auto h-5 text-yellow-500" /> </dt>
                                    <dd className="text-white font-light -mt-0.5">
                                        Komplek Kemendikbudristek, Gedung D Lantai 17
                                        Jl. Jenderal Sudirman, Senayan, Jakarta 10270
                                    </dd>
                                </div>
                                <div className="flex-row flex gap-1 ">
                                    <dt><Icon.IconPhospor.Envelope className="w-auto h-5 text-yellow-500" /> </dt>
                                    <dd className="text-white font-light -mt-0.5">
                                        <a href="mailto:mitrasdudi@kemdikbud.go.id">mitrasdudi@kemdikbud.go.id</a>
                                    </dd>
                                </div>
                            </div>
                            <div className="flex flex-col gap-1.5">
                                <div className="flex-row flex gap-1">
                                    <dt><Icon.IconPhospor.InstagramLogo className="w-auto h-5 text-yellow-500" /> </dt>
                                    <dd className="text-white font-extralight -mt-0.5">
                                        <a target="_blank" href="https://instagram.com/mitrasdudi" rel="noreferrer">@mitrasdudi</a>
                                    </dd>
                                </div>
                                <div className="flex-row flex gap-1 ">
                                    <dt><Icon.IconPhospor.TwitterLogo className="w-auto h-5 text-yellow-500" /> </dt>
                                    <dd className="text-white font-extralight -mt-0.5">
                                        <a target="_blank" href="https://twitter.com/mitrasdudi" rel="noreferrer">@mitrasdudi</a>
                                    </dd>
                                </div>
                                <div className="flex-row flex gap-1 ">
                                    <dt><Icon.IconPhospor.YoutubeLogo className="w-auto h-5 text-yellow-500" /> </dt>
                                    <dd className="text-white font-extralight -mt-0.5">
                                        <a target="_blank" href="https://www.youtube.com/channel/UC2LYkSxOBYlfySjEjUpLzQg" rel="noreferrer">Mitrasdudi Kemdikbud</a>
                                    </dd>
                                </div>
                                <div className="flex-row flex gap-1 ">
                                    <dt><Icon.IconPhospor.FacebookLogo className="w-auto h-5 text-yellow-500" /> </dt>
                                    <dd className="text-white font-extralight -mt-0.5">
                                        <a target="_blank" href="https://www.facebook.com/mitrasdudikemendikbud/" rel="noreferrer">Direktorat Kemitraan dan Penyelarasan DUDI</a>
                                    </dd>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="flex flex-col items-start">
                        <h4 className="text-white font-bold mb-2">Navigasi</h4>
                        <div className="flex items-center">
                            <NavLink to="/" className={navigationLinkClassName}>
                                {({ isActive }) => <Fragment>{!isActive ? null : <Icon.IconPhospor.ArrowLineRight className="w-3 h-auto" />} Beranda</Fragment>}
                            </NavLink>
                        </div>
                        <div className="flex items-center">
                            <NavLink to="/layanan" className={navigationLinkClassName}>
                                {({ isActive }) => <Fragment>{!isActive ? null : <Icon.IconPhospor.ArrowLineRight className="w-3 h-auto" />} Layanan</Fragment>}
                            </NavLink>
                        </div>
                        <div className="flex items-center">
                            <NavLink to="/informasi-publik" className={navigationLinkClassName}>
                                {({ isActive }) => <Fragment>{!isActive ? null : <Icon.IconPhospor.ArrowLineRight className="w-3 h-auto" />} Informasi Publik</Fragment>}

                            </NavLink>
                        </div>
                        <div className="flex items-center">
                            <NavLink to="/pengaduan" className={navigationLinkClassName}>
                                {({ isActive }) => <Fragment>{!isActive ? null : <Icon.IconPhospor.ArrowLineRight className="w-3 h-auto" />} Pengaduan</Fragment>}

                            </NavLink>
                        </div>
                        <div className="flex items-center">
                            <NavLink to="/kontak" className={navigationLinkClassName}>
                                {({ isActive }) => <Fragment>{!isActive ? null : <Icon.IconPhospor.ArrowLineRight className="w-3 h-auto" />} Kontak</Fragment>}
                            </NavLink>
                        </div>
                    </section>
                </div>
            </section>
        </footer>
    )
}