

export function StickySection({title, children}) {
    return (
        <section className="max-w-7xl mx-auto relative px-10 ">
            <section className="flex flex-col items-center">
                <div className="sticky top-0 pt-24 z-10 bg-white w-full">
                    <div className="border-b-2 w-6 border-primary"></div>
                    <h2 className="text-4xl tracking-tight font-extrabold text-primary text-center leading-10 sm:text-5xl md:text-6xl mb-10">
                        {title}
                    </h2>
                </div>
                {children}
            </section>
        </section>
    )
}