import { useSearchParams } from "react-router-dom";
import { PaddedContainer } from "Common/UI/Layout/Container";
import CalenderView from "Common/UI/Block/CalenderView";
import moment from "moment";
import { SearchParams } from "Common/Utility/search-params";
import { useCalendarAttendance } from "Domain/Calendar/API/CalendarAPI";
import { transformResponseToSingle } from "Common/Utility/formatter";
import Loading from "Common/UI/Component/Loading";
import { Fragment } from "react";
import { generateArray } from "Common/Utility/generator";
import classNames from "classnames";
import { useEffect } from "react";
import { Card, CardBody, CardFooter, CardHeader, Typography } from "@material-tailwind/react";
import Button from "Common/UI/Component/Button";

export default function Page() {
    const [searchParams, setSearchParams] = useSearchParams();
    const period = searchParams.get('period')
    const date = searchParams.get('date')
    const momentPeriod = period ? moment(period, 'YYYY-MM') : moment()
    const startDate = momentPeriod.startOf('month').format('YYYY-MM-DD')
    const endDate = momentPeriod.add(1, 'month').endOf('month').format('YYYY-MM-DD')


    const calenderAttendance = useCalendarAttendance({ startDate, endDate, properties: ['holidays', 'events', 'isHoliday'] })
    const calenderAttendances = transformResponseToSingle(calenderAttendance.data)

    const nextAgendas = [
        ...(!calenderAttendances ? [] : Object.entries(calenderAttendances).map(([date, { holidays }]) => holidays).flat().filter(agenda => moment(agenda.date, 'YYYY-MM-DD').isSameOrAfter(moment()) ))
    ]

    return (
        <PaddedContainer className=" bg-white pt-10 pb-24">
            <div className="flex flex-row justify-center mt-10 items-start">
                <div className="grid grid-cols-1 lg:grid-cols-3 max-w-7xl w-full items-start gap-5">
                    <div className="lg:col-span-2">
                        <CalenderView
                            title="Agenda Mitras DUDI"
                            additionalMenu={(
                                <div>

                                </div>
                            )}
                            selectableDateOn="date-label"
                            selectedDate={date}
                            onSelectedDate={({ date }) => setSearchParams(SearchParams.updates(searchParams, { 'date': date }))}
                            initialCursorDate={period ? moment(period, 'YYYY-MM-DD') : moment()}
                            onChangeCursor={(cursorDate) => setSearchParams({ period: cursorDate.format('YYYY-MM') })}
                            classItem={day => !calenderAttendance.isLoading && calenderAttendances[day.date]?.isHoliday ? 'bg-red-100' : null}
                            renderItem={day => (
                                <div className="pt-3" >
                                    {!day.isCurrentMonth ? null :
                                        calenderAttendance.isLoading || calenderAttendance.isLoading ? <Loading.Spinner size="20px" /> :
                                            (!calenderAttendances[day.date]) ? null :
                                                <Fragment>
                                                    {calenderAttendances[day.date]?.holidays?.map(holiday =>
                                                        <div>
                                                            {holiday.title}
                                                        </div>
                                                    )}
                                                </Fragment>
                                    }
                                </div>
                            )}
                            renderItemMobile={day => {
                                return (
                                    <button >
                                        {!day.isCurrentMonth ? null :
                                            calenderAttendance.isLoading || calenderAttendance.isLoading ? <Loading.Spinner size="20px" /> :
                                                <Fragment>
                                                    <div className="-mx-0.5 mt-auto flex flex-wrap-reverse">
                                                        {calenderAttendances[day.date]?.holidays.length === 0 ? null :
                                                            generateArray(calenderAttendances[day.date]?.holidays.length).map((_, index) =>
                                                                <div key={index} className={classNames("mx-0.5 mb-1 h-1.5 w-1.5 rounded-full", 'bg-rose-400')} />
                                                            )
                                                        }
                                                    </div>
                                                </Fragment>
                                        }
                                    </button>
                                )
                            }}
                        />
                    </div>
                    <div className="mt-5">
                        <h2 className="text-4xl tracking-tight font-extrabold text-primary text-center leading-10 sm:text-2xl md:text-4xl mb-10">
                            Agenda selanjutnya
                        </h2>
                        <div className="grid grid-cols-1 gap-2.5">
                            {nextAgendas.map((agenda) => (
                                <Card key={agenda.ID} className="w-full max-w-[48rem] flex-row relative">
                                    <CardHeader
                                        shadow={false}
                                        floated={false}
                                        className="m-0 w-2/5 shrink-0 rounded-r-none relative"
                                    >
                                        <img
                                            src="https://images.unsplash.com/photo-1522202176988-66273c2fd55f?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1471&q=80"
                                            alt="card-image"
                                            className="h-full w-full object-cover"
                                        />
                                        <div className="to-bg-black-10 absolute inset-0 h-full w-full bg-gradient-to-t from-black/80 via-black/50" />

                                        <div className="absolute bottom-1 right-1 text-right ">
                                            <Typography variant="h3" color="white" className="">
                                                {moment(agenda.date, 'YYYY-MM-DD').format('D')}
                                            </Typography>
                                            <Typography variant="p" color="white" className="-mt-3.5">
                                                {moment(agenda.date, 'YYYY-MM-DD').format('MMMM')}
                                            </Typography>
                                        </div>
                                    </CardHeader>
                                    <CardBody className="h-[100px]">
                                        <Typography variant="h6" color="blue-gray" className="mb-2 line-clamp-2 text-sm">
                                            {agenda.title}
                                        </Typography>
                                        <a href={`https://www.google.com/search?q=${agenda.title}`} className="inline-block ">
                                            <Button.LoadableButton variant="light">
                                                Lihat
                                            </Button.LoadableButton>
                                        </a>
                                    </CardBody>

                                </Card>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </PaddedContainer>
    )
}