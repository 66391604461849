import { Carousel, Typography, Button, Card, CardHeader, CardBody, Avatar, Dialog, DialogHeader, DialogBody, DialogFooter } from "@material-tailwind/react";
import { Fragment, useEffect } from "react";

import {
    StackedCarousel,
    ResponsiveContainer,
} from "react-stacked-center-carousel";

import { useRef } from "react";
import { CardPhotoStory, CardVocationStory } from "./Card";
import { Fab } from "@mui/material";
import Icon from "Common/UI/Component/Icon";

import { Link } from "react-router-dom";
import moment from "moment";
import { TODAY_MOMENT } from "Common/Constant/Date";
import { cn } from "Common/Utility/class-name";
import { intToRGB } from "Common/Utility/colorize";
import { hashCode } from "Common/Utility/hash";
import { ItemPartnerCompany } from "./Item";
import { useVisitedStore } from "./store";
import { useState } from "react";
import ReactGA from "react-ga4";

import MultiCarousel from "react-multi-carousel";
import 'react-multi-carousel/lib/styles.css';
import { usePublishedHeroBanners } from "Domain/Content/HeroBannerAPI";
import { usePublishedPerspectiveStories } from "Domain/Content/PerspectiveStoryAPI";
import { usePublishedVocationStories } from "Domain/Content/VocationStoryAPI";
import { usePublishedPhotoStories } from "Domain/Content/PhotoStoryAPI";
import { useFeaturedPartnerCompany } from "Domain/Content/PartnerCompanyAPI";
import { usePublishedFeaturedNews } from "Domain/Content/FeaturedNewsAPI";

export default function Page() {
    const publishedFeaturedNews = usePublishedFeaturedNews({})
    const publishedHeroBanners = usePublishedHeroBanners({})
    const publishedPerspectiveStories = usePublishedPerspectiveStories({})
    const publishedVocationStories = usePublishedVocationStories({})
    const publishedPhotoStories = usePublishedPhotoStories({})
    const featuredPartnerCompanies = useFeaturedPartnerCompany({})

    const [showBanner, setShowBanner] = useState(false)
    const { isSeenBanner, flagAsSeenBanner } = useVisitedStore(({ isSeenBanner, flagAsSeenBanner }) => ({ isSeenBanner, flagAsSeenBanner }))
    const handleOpenBanner = () => setShowBanner(previous => {
        if (previous) {
            flagAsSeenBanner(true)
        }
        return !previous
    })
    const carouselVocationStoryRef = useRef();
    const carouselPhotoStoryRef = useRef();

    const selector = (response) => response.data?.data?.docs || []
    const featuredNews = selector(publishedFeaturedNews)
    const heroBanners = selector(publishedHeroBanners)
    const perspectiveStories = selector(publishedPerspectiveStories)
    const vocationStories = selector(publishedVocationStories)
    const photoStories = selector(publishedPhotoStories)
    const partnerCompanies = selector(featuredPartnerCompanies)

    useEffect(()=> {
        ReactGA.send({ hitType: "pageview", page: "/", title: "Home" });
    },[])

    useEffect(() => {
        if (carouselVocationStoryRef.current) {
            const intervalID = setInterval(() => {
                carouselVocationStoryRef.current.goNext()
            }, 2000)

            return () => clearInterval(intervalID)
        }
    }, [carouselVocationStoryRef.current])


    useEffect(() => {
        if (carouselPhotoStoryRef.current) {
            const intervalID = setInterval(() => {
                carouselPhotoStoryRef.current.goNext()
            }, 2000)

            return () => clearInterval(intervalID)
        }
    }, [carouselPhotoStoryRef.current])

    useEffect(() => {
        if (!isSeenBanner) {
            const timeoutID = setTimeout(() => {
                setShowBanner(true)
            }, 2000)

            return () => clearTimeout(timeoutID)
        }
    }, [isSeenBanner])

    return (
        <Fragment>
            <section>
                <Carousel className="rounded-xl" autoplay={true} loop={true} transition={{ type: "tween", duration: 0.5 }}>
                    {heroBanners.filter(heroBanner => !heroBanner.expired || moment(heroBanner.expired, 'YYYY-MM-DD').isAfter(TODAY_MOMENT)).map(heroBanner =>
                        <div key={heroBanner.id} className="relative h-screen w-full">
                            <img
                                src={heroBanner.featuredImage.url}
                                alt={heroBanner.featuredImage.alt}
                                className="h-full w-full object-cover"
                            />
                            <div className="absolute inset-0 grid h-screen w-full items-center bg-black/75">
                                <div className="w-3/4 pl-16 md:w-3/4 md:pl-20 lg:pl-32">
                                    <Typography
                                        variant="h1"
                                        color="white"
                                        className="mb-4 text-xl pl-2 md:pl-0 md:text-4xl lg:text-5xl"
                                    >
                                        {heroBanner.title}
                                    </Typography>
                                    <Typography
                                        variant="lead"
                                        color="white"
                                        className="mb-12 opacity-80 hidden sm:block"
                                    >
                                        {heroBanner.shortSummary}
                                    </Typography>
                                    <div className="flex gap-2">
                                        <Link to={`/artikel/${heroBanner.articleID}`} target="_blank">
                                            <Button size="lg" color="orange" className="bg-orange-400/60 hover:bg-orange-400/100">
                                                Baca
                                            </Button>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </Carousel>
            </section>


            <div className="flex flex-col gap-20 mb-20">
                <section className="flex flex-col gap-10 my-10 w-full">
                    <div className="flex justify-center w-full">
                        <Typography
                            variant="h1"
                            color="orange"
                            className="mb-4 text-3xl md:text-4xl lg:text-5xl"
                        >
                            Perspektif
                        </Typography>
                    </div>

                    <div className="flex justify-center">
                        <div className="max-w-7xl">
                            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 mx-10 gap-10 ">
                                {perspectiveStories.map(story => {
                                    return (
                                        <Link key={story.id} to={`/artikel/${story.articleID}`} target="_blank" className="flex justify-center">
                                            <Card
                                                shadow={true}
                                                className="relative grid h-[40rem] w-full max-w-[28rem] items-end justify-center overflow-hidden text-center group cursor-pointer"
                                            >

                                                <CardHeader
                                                    floated={true}
                                                    shadow={true}
                                                    color="transparent"
                                                    className={`absolute inset-0 m-0 h-full w-full rounded-none bg-[url('')] bg-cover bg-center`}
                                                >
                                                    <img src={story.featuredImage.url} alt={story.featuredImage.alt} className={`absolute inset-0 m-0 h-full w-full rounded-none object-cover group-hover:transition group-hover:duration-500 group-hover:scale-125`} />
                                                    <div className="to-bg-black-10 absolute inset-0 h-full w-full bg-gradient-to-t from-black/80 via-black/50" />
                                                </CardHeader>
                                                <CardBody className="relative py-14 px-6 md:px-12">

                                                    <Typography
                                                        variant="h5"
                                                        color="white"
                                                        className="mb-6 font-medium leading-[1.5]"
                                                    >
                                                        {story.title}
                                                    </Typography>
                                                    <Typography variant="h6" className="mb-4 text-gray-400 font-extralight">
                                                        {story.shortSummary}
                                                    </Typography>
                                                    <Avatar
                                                        size="xl"
                                                        variant="circular"
                                                        alt={story.author.name}
                                                        className="border-2 border-white"
                                                        src={story.author.avatar.url}
                                                    />
                                                </CardBody>
                                                <div className="absolute top-3.5 right-3.5">
                                                    <span className={cn("inline-flex items-center rounded-full  px-3 py-0.5 text-sm font-medium", `text-white`)} style={{ backgroundColor: `#${intToRGB(hashCode(story.category))}` }}>
                                                        {story.category}
                                                    </span>
                                                </div>
                                            </Card>
                                        </Link>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                </section>

                <section className="flex flex-col gap-10 my-10 w-full">
                    <div className="flex justify-center w-full">
                        <Typography
                            variant="h1"
                            color="orange"
                            className="mb-4 text-3xl md:text-4xl lg:text-5xl"
                        >
                            Cerita Vokasi
                        </Typography>
                    </div>

                    {!vocationStories.length ? null :
                        <div className="flex justify-center">
                            <div style={{ width: "100%", position: "relative" }}>
                                <ResponsiveContainer
                                    carouselRef={carouselVocationStoryRef}
                                    render={(parentWidth, carouselRef) => {
                                        // If you want to use a ref to call the method of StackedCarousel, you cannot set the ref directly on the carousel component
                                        // This is because ResponsiveContainer will not render the carousel before its parent's width is determined
                                        // parentWidth is determined after your parent component mounts. Thus if you set the ref directly it will not work since the carousel is not rendered
                                        // Thus you need to pass your ref object to the ResponsiveContainer as the carouselRef prop and in your render function you will receive this ref object
                                        let currentVisibleSlide = 5;
                                        if (parentWidth <= 1440) currentVisibleSlide = 3;
                                        if (parentWidth <= 1080) currentVisibleSlide = 1;
                                        return (
                                            <StackedCarousel
                                                ref={carouselRef}
                                                slideComponent={CardVocationStory}
                                                slideWidth={parentWidth < 800 ? parentWidth - 40 : 373}
                                                carouselWidth={parentWidth}
                                                data={vocationStories}
                                                currentVisibleSlide={currentVisibleSlide}
                                                maxVisibleSlide={5}
                                                useGrabCursor
                                                customScales={[1, 1, 1, 1]}
                                                fadeDistance={0.01}
                                                transitionTime={450}
                                            />
                                        );
                                    }}
                                />
                                <>
                                    <Fab
                                        style={{ position: "absolute", top: "40%", left: 10, zIndex: 10 }}
                                        size="small"
                                        color="primary"
                                        onClick={() => {
                                            carouselVocationStoryRef.current?.goBack();
                                        }}
                                    >
                                        <Icon.IconSolid.ArrowLeftCircleIcon />
                                    </Fab>
                                    <Fab
                                        style={{ position: "absolute", top: "40%", right: 10, zIndex: 10 }}
                                        size="small"
                                        color="primary"
                                        onClick={() => {
                                            carouselVocationStoryRef.current?.goNext(6);
                                        }}
                                    >
                                        <Icon.IconSolid.ArrowRightCircleIcon />
                                    </Fab>
                                </>
                            </div>
                        </div>
                    }

                </section>
                <section>
                    <div className="flex justify-center w-full">
                        <Typography
                            variant="h1"
                            color="orange"
                            className="mb-4 text-3xl md:text-4xl lg:text-5xl"
                        >
                            Berita Terbaru
                        </Typography>
                    </div>

                    <div className="relative bg-gray-50 px-6 pb-20 lg:px-8  lg:pb-28">
                        <div className="absolute inset-0">
                            <div className="h-1/3 bg-white sm:h-2/3" />
                        </div>
                        <div className="relative mx-auto max-w-7xl">

                            <div className="mx-auto mt-12 grid max-w-lg gap-5 lg:max-w-none lg:grid-cols-3">
                                {featuredNews.map((featuredNew) => (
                                    <div key={featuredNew.id} className="flex flex-col overflow-hidden rounded-lg shadow-lg">
                                        <div className="flex-shrink-0">
                                            <img className="h-48 w-full object-cover" src={featuredNew.featuredImage.url} alt="" />
                                        </div>
                                        <div className="flex flex-1 flex-col justify-between bg-white p-6">
                                            <div className="flex-1">
                                                <span className="inline-flex items-center rounded-full bg-indigo-100 px-3 py-0.5 text-sm font-medium text-indigo-800">
                                                    <svg className="-ml-1 mr-1.5 h-2 w-2 text-indigo-400" fill="currentColor" viewBox="0 0 8 8">
                                                        <circle cx={4} cy={4} r={3} />
                                                    </svg>
                                                    {(new URL(featuredNew.url)).hostname}
                                                </span>
                                                <a href={featuredNew.url} target="_blank" className="mt-2 block">
                                                    <p className="text-xl font-semibold text-gray-900">{featuredNew.title}</p>
                                                    <p className="mt-3 text-base text-gray-500">{featuredNew.shortSummary}</p>
                                                </a>
                                            </div>

                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </section>

                <section>
                    <div className="flex justify-center w-full">
                        <Typography
                            variant="h1"
                            color="orange"
                            className="mb-4 text-3xl md:text-4xl lg:text-5xl"
                        >
                            Photostory
                        </Typography>
                    </div>

                    {publishedPhotoStories.isLoading || photoStories.length <= 0 ? null :
                        <div className="flex justify-center">
                            <div style={{ width: "100%", position: "relative" }}>
                                <ResponsiveContainer
                                    carouselRef={carouselPhotoStoryRef}
                                    render={(parentWidth, carouselRef) => {
                                        // If you want to use a ref to call the method of StackedCarousel, you cannot set the ref directly on the carousel component
                                        // This is because ResponsiveContainer will not render the carousel before its parent's width is determined
                                        // parentWidth is determined after your parent component mounts. Thus if you set the ref directly it will not work since the carousel is not rendered
                                        // Thus you need to pass your ref object to the ResponsiveContainer as the carouselRef prop and in your render function you will receive this ref object
                                        let currentVisibleSlide = 5;
                                        if (parentWidth <= 1440) currentVisibleSlide = 3;
                                        if (parentWidth <= 1080) currentVisibleSlide = 1;
                                        return (
                                            <StackedCarousel
                                                ref={carouselRef}
                                                slideComponent={CardPhotoStory}
                                                slideWidth={parentWidth < 800 ? parentWidth - 40 : 373}
                                                carouselWidth={parentWidth}
                                                data={photoStories}
                                                currentVisibleSlide={currentVisibleSlide}
                                                maxVisibleSlide={5}
                                                useGrabCursor
                                            />
                                        );
                                    }}
                                />
                                <>
                                    <Fab
                                        style={{ position: "absolute", top: "40%", left: 10, zIndex: 10 }}
                                        size="small"
                                        color="primary"
                                        onClick={() => {
                                            carouselPhotoStoryRef.current?.goBack();
                                        }}
                                    >
                                        <Icon.IconSolid.ArrowLeftCircleIcon />
                                    </Fab>
                                    <Fab
                                        style={{ position: "absolute", top: "40%", right: 10, zIndex: 10 }}
                                        size="small"
                                        color="primary"
                                        onClick={() => {
                                            carouselPhotoStoryRef.current?.goNext(6);
                                        }}
                                    >
                                        <Icon.IconSolid.ArrowRightCircleIcon />
                                    </Fab>
                                </>
                            </div>
                        </div>
                    }

                </section>

                <section>
                    <div className="flex justify-center w-full">
                        <Typography
                            variant="h1"
                            color="orange"
                            className="mb-4 text-3xl md:text-4xl lg:text-5xl"
                        >
                            Mitra Berdedikasi
                        </Typography>
                    </div>

                    <div className="bg-white">
                        <div className="mx-auto max-w-7xl py-12 px-6 lg:px-8">
                            <MultiCarousel
                                additionalTransfrom={0}
                                arrows
                                autoPlaySpeed={3000}
                                centerMode={false}
                                className=""
                                containerClass="container-with-dots"
                                dotListClass=""
                                draggable
                                focusOnSelect={false}
                                infinite
                                itemClass=""
                                keyBoardControl
                                minimumTouchDrag={80}
                                pauseOnHover
                                renderArrowsWhenDisabled={false}
                                renderButtonGroupOutside={true}
                                renderDotsOutside={false}
                                autoPlay={true}
                                responsive={{
                                    desktop: {
                                        breakpoint: {
                                            max: 3000,
                                            min: 1024
                                        },
                                        items: 5,
                                        partialVisibilityGutter: 40
                                    },
                                    mobile: {
                                        breakpoint: {
                                            max: 464,
                                            min: 0
                                        },
                                        items: 1,
                                        partialVisibilityGutter: 30
                                    },
                                    tablet: {
                                        breakpoint: {
                                            max: 1024,
                                            min: 464
                                        },
                                        items: 2,
                                        partialVisibilityGutter: 30
                                    }
                                }}
                                rewind={false}
                                rewindWithAnimation={false}
                                rtl={false}
                                shouldResetAutoplay
                                showDots={false}
                                sliderClass=""
                                slidesToSlide={1}
                                swipeable
                            >
                                {partnerCompanies.map(partnerCompany =>
                                    <div key={partnerCompany.id} className="mx-5">
                                        <ItemPartnerCompany
                                            partnerCompany={partnerCompany}
                                        />
                                    </div>
                                )}
                            </MultiCarousel>
                        </div>


                    </div>
                </section>
            </div>

            <Dialog size="xl" open={showBanner} handler={handleOpenBanner}>
                <DialogBody className="relative">
                    <img
                        alt="nature"
                        className="h-auto w-full rounded-lg object-cover object-center"
                        src={require('Asset/Dummy/PopUp/000001-revision.webp')}
                    />
                    <div className="top-0 bottom-0 absolute flex flex-col items-center left-0 right-0">
                        <a href="https://form.mitrasdudi.id/fill/2" target="_blank" className="animate animate-bounce absolute bottom-52 font-extrabold inline-flex items-center rounded-full border border-transparent ring-white ring-1 bg-indigo-600 px-6 py-3 text-2xl text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                            Ajukan Pengaduan
                        </a>
                    </div>
                </DialogBody>
            </Dialog>
        </Fragment>
    )
}