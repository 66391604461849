import Icon from "Common/UI/Component/Icon"


const toIcon = (extension) => {
    return ({
        'png': Icon.IconPhospor.FilePng,
        'jpg': Icon.IconPhospor.FileJpg,
        'jpeg': Icon.IconPhospor.FileJpg,
    }[extension]) || Icon.IconPhospor.File
}


export default { toIcon }