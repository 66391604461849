import Icon from "Common/UI/Component/Icon";
import { PaddedContainer } from "Common/UI/Layout/Container";
import React, { Fragment } from "react";
import Avatar from "Common/UI/Component/Avatar";
import { toInitial } from "Common/Utility/formatter";
import { Link } from "react-router-dom";
import { useImagePreviewDialogStore } from "Common/UI/Block/ImagePreviewDialog";
import { PhotoProvider, PhotoView } from "react-photo-view";

export default function Page() {
    const imagePreview = useImagePreviewDialogStore()
    /**
     * https://youtu.be/TvRwb-Q57S8
https://youtu.be/c3I7s5LcVJE
https://youtu.be/rDze7OPZn_0
https://youtu.be/2zLIVFOo2Nc

     */
    const videos = [
        {
            title: 'Standar Pelayanan Kemitraan Pendidikan Vokasi Antara DUDI dan Satuan Pendidikan Vokasi',
            categogry: 'Motion Graphic',
            imageUrl:
                '/files/video/thumb/video-1.png',
            videoUrl: 'https://youtu.be/TvRwb-Q57S8'
        },
        {
            title: 'Standar Pelayanan Kemitraan Pendidikan Vokasi Dengan DUDI',
            categogry: 'Motion Graphic',
            imageUrl:
                '/files/video/thumb/video-2.png',
            videoUrl: 'https://youtu.be/c3I7s5LcVJE'
        },
        {
            title: 'Standar Pelayanan Kemitraan Pendidikan Vokasi',
            categogry: 'Motion Graphic',
            imageUrl:
                '/files/video/thumb/video-3.png',
            videoUrl: 'https://youtu.be/rDze7OPZn_0'
        },
        {
            title: 'Standar Pelayanan Rekomendasi Pembentukan LSP P1 SMK_1',
            categogry: 'Motion Graphic',
            imageUrl:
                '/files/video/thumb/video-5.png',
            videoUrl: 'https://youtu.be/2zLIVFOo2Nc'
        },
        // More people...
    ]
    const posInfographics = [
        {
            name: 'POS Layanan Keberatan atas Informasi Publik',
            imageUrl: `/files/infografis/POS Layanan Keberatan atas Informasi Publik1.png`,
            links: [
                { type: 'show', url: '/files/infografis/POS Layanan Keberatan atas Informasi Publik1.png', fileInfo: { size: 944261, fileExtension: 'png' } },
                { type: 'download', fileType: 'image', url: '/files/infografis/POS Layanan Keberatan atas Informasi Publik1.png' },
            ]
        },
        {
            name: 'POS Penanganan Sengketa Informasi',
            imageUrl: `/files/infografis/POS Penanganan Sengketa Informasi.png`,
            links: [
                { type: 'show', url: '/files/infografis/POS Penanganan Sengketa Informasi.png', fileInfo: { size: 824386, fileExtension: 'png' } },
                { type: 'download', fileType: 'image', url: '/files/infografis/POS Penanganan Sengketa Informasi.png' },
            ]
        },
        {
            name: 'POS Permohonan Informasi Publik',
            imageUrl: `/files/infografis/ROLL BANNER POS Permohonan Informasi Publik copy1.png`,
            links: [
                { type: 'show', url: '/files/infografis/ROLL BANNER POS Permohonan Informasi Publik copy1.png', fileInfo: { size: 991973, fileExtension: 'png' } },
                { type: 'download', fileType: 'image', url: '/files/infografis/ROLL BANNER POS Permohonan Informasi Publik copy1.png' },
            ]
        },
    ]
    const serviceStandardInfographics = [
        {
            name: 'Kemitraan Mitrasdudi dengan Satuan Pendidikan Vokasi',
            imageUrl: `/files/infografis/SP Kemitraan mitrasdudi dengan satuan pendidikan vokasi 16_9-01.webp`,
            links: [
                { type: 'show', url: '/files/infografis/SP Kemitraan mitrasdudi dengan satuan pendidikan vokasi 16_9-01.png', fileInfo: { size: 1877949, fileExtension: 'png' } },
                { type: 'download', fileType: 'image', url: '/files/infografis/SP Kemitraan mitrasdudi dengan satuan pendidikan vokasi 16_9-01.png' },
            ]
        },
        {
            name: 'Kemitraan Pendidikan Vokasi',
            imageUrl: `/files/infografis/SP kemitraan pendidikan vokasi 16_9-01.webp`,
            links: [
                { type: 'show', url: '/files/infografis/SP kemitraan pendidikan vokasi 16_9-01.png', fileInfo: { size: 2069512, fileExtension: 'png' } },
                { type: 'download', fileType: 'image', url: '/files/infografis/SP kemitraan pendidikan vokasi 16_9-01.png' },
            ]
        },
        {
            name: 'Penyelarasan Pendidikan Vokasi dengan Dunia Usaha dan Dunia Industri',
            imageUrl: `/files/infografis/Standar Pelayanan Penyelarasan Pendidikan Vokasi dengan Dunia Usaha dan Dunia Industri-05.webp`,
            links: [
                { type: 'show', url: '/files/infografis/Standar Pelayanan Penyelarasan Pendidikan Vokasi dengan Dunia Usaha dan Dunia Industri-05.jpg', fileInfo: { size: 3224023, fileExtension: 'jpg' } },
                { type: 'download', fileType: 'image', url: '/files/infografis/Standar Pelayanan Penyelarasan Pendidikan Vokasi dengan Dunia Usaha dan Dunia Industri-05.jpg' },
            ]
        },
        {
            name: 'Penyelarasan Pendidikan Vokasi dengan Dunia Usaha dan Dunia Industri',
            imageUrl: `/files/infografis/Standar Pelayanan Penyelarasan Pendidikan Vokasi dengan Dunia Usaha dan Dunia Industri.webp`,
            links: [
                { type: 'show', url: '/files/infografis/Standar Pelayanan Penyelarasan Pendidikan Vokasi dengan Dunia Usaha dan Dunia Industri.jpg', fileInfo: { size: 3502019, fileExtension: 'jpg' } },
                { type: 'download', fileType: 'image', url: '/files/infografis/Standar Pelayanan Penyelarasan Pendidikan Vokasi dengan Dunia Usaha dan Dunia Industri.jpg' },
            ]
        },
    ]

    const folders = [
        { icon: Icon.IconPhospor.Folder, title: 'Informasi Setiap Saat', color: '#219ba8', route: '/informasi-publik/setiap-saat' },
        { icon: Icon.IconPhospor.FileText, title: 'Informasi Berkala', color: '#1c2855', route: '/informasi-publik/berkala' },
        { icon: Icon.IconPhospor.Folder, title: 'Informasi Serta Merta', color: '#1c2855', route: '/informasi-publik/serta-merta' },
        { icon: Icon.IconPhospor.FileSearch, title: 'Permohonan Informasi', color: '#219ba8', url: 'https://form.mitrasdudi.id/fill/2' },
    ]

    const renderSubSubNode = (node) => {
        return (
            <Fragment>
                <div className="mt-6 flex">
                    <div className="mr-4 flex-shrink-0">
                        <Avatar.Single image={node.data.image} label={toInitial(node.data.name)} />
                    </div>
                    <div>
                        <h4 className="text-lg font-bold">{node.data.name}</h4>
                        <p className="mt-1">
                            {node.data.title}
                        </p>
                    </div>
                </div>
                {node.children?.map(children => renderSubSubNode(children))}
            </Fragment>
        )
    }

    return (
        <PaddedContainer className="min-h-screen bg-white">
            <div className="flex flex-col min-h-screen items-start my-20 gap-16 justify-center">
                <section className="flex  flex-col justify-center w-full">
                    <section className="flex flex-col items-center mb-32 ">
                        <div className="border-b-2 w-6 border-primary"></div>
                        <h2 className="text-4xl tracking-tight font-extrabold text-primary sm:text-5xl md:text-6xl">
                            Layanan Informasi dan Dokumentasi
                        </h2>
                        <div className="flex justify-center max-w-7xl text-center">
                            <p className="tracking-tight font-medium font-[Lora] leading-relaxed text-xl">
                                Direktorat Kemitraan dan Penyelarasan Dunia Usaha dan Dunia Industri mempunyai Layanan Informasi dan Dokumentasi.
                                Berdasarkan dengan Peraturan Pemerintah tentang Pelaksanaan Undang-Undang Nomor 14 Tahun 2008 tentang Keterbukaan Informasi Publik.
                                Informasi Publik adalah informasi yang dihasilkan, disimpan, dikelola, dikirim, dan/atau diterima oleh suatu badan publik yang berkaitan dengan
                                penyelenggara dan penyelenggaraan publik dan/atau penyelenggara dan penyelenggaraan badan publik lainnya serta informasi lain yang berkaitan dengan
                                kepentingan publik. Setiap informasi publik bersifat terbuka dan dapat diakses oleh setiap pengguna informasi publik, kecuali informasi publik yang
                                dikecualikan, Informasi publik yang dikecualikan bersifat ketat dan terbatas. Setiap informasi publik harus dapat diperoleh setiap pemohon informasi
                                publik dengan mekanisme memperoleh informasi yang cepat dan tepat waktu, biaya ringan dan cara sederhana.

                            </p>
                        </div>
                    </section>

                    <nav className="flex gap-36 justify-center">
                        <div className="grid grid-cols-2 gap-2">
                            {folders.map(({ icon, title, color, url, route }) => (
                                route ?
                                    <Link to={route} key={title} className={`py-4 px-10 bg-[${color}] rounded-lg cursor-pointer hover:opacity-40 hover:animate-[wiggle_1s_ease-in-out_infinite]`}>
                                        <section className="text-white font-bold flex flex-col items-center gap-1">
                                            {React.createElement(icon, { className: "h-28 w-auto" })}
                                            <p className="w-3/5 text-center leading-4 mb-3">{title}</p>
                                            <div className="border-b-2 w-6 border-white"></div>
                                        </section>
                                    </Link> :
                                    <a key={title} href={url} target="_blank" className={`py-4 px-10 bg-[${color}] rounded-lg cursor-pointer hover:opacity-40 hover:animate-[wiggle_1s_ease-in-out_infinite]`} rel="noreferrer">
                                        <section className="text-white font-bold flex flex-col items-center gap-1">
                                            {React.createElement(icon, { className: "h-28 w-auto" })}
                                            <p className="w-3/5 text-center leading-4 mb-3">{title}</p>
                                            <div className="border-b-2 w-6 border-white"></div>
                                        </section>
                                    </a>

                            ))}
                        </div>
                    </nav>
                </section>
                <section className="flex flex-col w-full">
                    <section className="max-w-7xl mx-auto relative px-10">
                        <div className="border-b-2 w-6 border-primary"></div>
                        <h2 className="text-4xl tracking-tight font-extrabold text-primary sm:text-5xl md:text-6xl">
                            Layanan Informasi dan Dokumentasi
                        </h2>
                        <div className="flex flex-col gap-4 my-6 ">
                            <section className="">
                                <h3 className="text-md tracking-tight font-extrabold text-primary sm:text-md md:text-3xl mb-5">
                                    Tugas dan Fungsi PPID sesuai dengan Permendikbud 41 Tahun 2020 adalah sebagai
                                    berikut:
                                </h3>
                                <ol className="list-decimal flex flex-col gap-2 mt-5 ml-10 ">
                                    <li className="tracking-tight font-medium font-[Lora] leading-relaxed text-xl">
                                        Penyediaan, penyimpanan, pendokumentasian, dan pengamanan Informasi;
                                    </li>
                                    <li className="tracking-tight font-medium font-[Lora] leading-relaxed text-xl">
                                        Pelayanan Informasi sesuai dengan aturan yang berlaku;
                                    </li>
                                    <li className="tracking-tight font-medium font-[Lora] leading-relaxed text-xl">
                                        Pelayanan Informasi Publik yang cepat, tepat, dan sederhana;
                                    </li>
                                    <li className="tracking-tight font-medium font-[Lora] leading-relaxed text-xl">
                                        Penetapan prosedur operasional penyebarluasan Informasi Publik;
                                    </li>
                                    <li className="tracking-tight font-medium font-[Lora] leading-relaxed text-xl">
                                        Pengujian Konsekuensi;
                                    </li>
                                    <li className="tracking-tight font-medium font-[Lora] leading-relaxed text-xl">
                                        Pengklasifikasian Informasi dan/atau pengubahannya;
                                    </li>
                                    <li className="tracking-tight font-medium font-[Lora] leading-relaxed text-xl">
                                        Penetapan Informasi Publik yang Dikecualikan yang telah habis jangka waktu pengecualiannya sebagai Informasi Publik yang dapat diakses;
                                    </li>
                                    <li className="tracking-tight font-medium font-[Lora] leading-relaxed text-xl">
                                        Penetapan pertimbangan tertulis atas setiap kebijakan yang diambil untuk memenuhi hak setiap orang atas Informasi Publik;
                                    </li>
                                    <li className="tracking-tight font-medium font-[Lora] leading-relaxed text-xl">
                                        Menyelesaikan sengketa Informasi Publik unit organisasi atau unit kerja yang bersangkutan; dan
                                    </li>
                                    <li className="tracking-tight font-medium font-[Lora] leading-relaxed text-xl">
                                        Melakukan evaluasi terhadap PPID di unit organisasi atau unit kerja yang bersangkutan
                                    </li>
                                </ol>
                            </section>
                            <section className="">
                                <h3 className="text-md tracking-tight font-extrabold text-primary sm:text-md md:text-3xl mb-5">
                                    Tugas dan Fungsi Koordinator PPID sesuai dengan Permendikbud 41 Tahun 2020 adalah
                                    sebagai berikut:
                                </h3>
                                <ol className="list-decimal flex flex-col gap-2 mt-5 ml-10">
                                    <li className="tracking-tight font-medium font-[Lora] leading-relaxed text-xl">
                                        Membantu PPID Kementerian dalam melaksanakan tugas layanan Informasi Publik;
                                    </li>
                                    <li className="tracking-tight font-medium font-[Lora] leading-relaxed text-xl">
                                        Melakukan pembinaan pelaksanaan pelayanan Informasi Publik PPID Kementerian;
                                    </li>
                                    <li className="tracking-tight font-medium font-[Lora] leading-relaxed text-xl">
                                        Melakukan pendampingan kepada PPID Kementerian dalam menetapkan Daftar Informasi Publik;
                                    </li>
                                    <li className="tracking-tight font-medium font-[Lora] leading-relaxed text-xl">
                                        Melakukan pendampingan pelaksanaan Pengujian Konsekuensi kepada PPID Kementerian dalam menetapkan Daftar Informasi Publik yang
                                        Dikecualikan;
                                    </li>
                                    <li className="tracking-tight font-medium font-[Lora] leading-relaxed text-xl">
                                        Memberikan persetujuan Daftar Informasi Publik yang Dikecualikan;
                                    </li>
                                    <li className="tracking-tight font-medium font-[Lora] leading-relaxed text-xl">
                                        Melakukan pengadministrasian penetapan Daftar Informasi Publik dan penetapan uji konsekuensi;
                                    </li>
                                    <li className="tracking-tight font-medium font-[Lora] leading-relaxed text-xl">
                                        Melakukan pendampingan dan memberikan pertimbangan kepada PPID Kementerian terhadap penyelesaian sengketa Informasi Publik yang diajukan
                                        oleh Pemohon Informasi Publik; dan
                                    </li>
                                    <li className="tracking-tight font-medium font-[Lora] leading-relaxed text-xl">
                                        Menyampaikan laporan pelayanan Informasi Publik tahunan kepada Atasan PPID Kementerian dengan tembusan Menter
                                    </li>
                                </ol>
                            </section>
                        </div>
                    </section>
                </section>
                <section className="flex flex-col w-full">
                    <section className="max-w-7xl mx-auto relative px-10">
                        <div className="border-b-2 w-6 border-primary"></div>
                        <h2 className="text-4xl tracking-tight font-extrabold text-primary sm:text-5xl md:text-6xl">
                            Infografis
                        </h2>
                        <div className="mx-auto max-w-7xl py-12 px-6 text-center lg:px-8 lg:py-24 flex flex-col gap-10">
                            <div className="space-y-12">
                                <div className="space-y-5 sm:mx-auto sm:max-w-xl sm:space-y-4 lg:max-w-5xl">
                                    <h2 className="text-3xl font-bold tracking-tight sm:text-4xl">Infografis POS</h2>
                                    <p className="text-xl text-gray-500">
                                        berikut semua infograsi yang tersedia terkait dengan POS
                                    </p>
                                </div>
                                <ul
                                    role="list"
                                    className="mx-auto space-y-16 sm:grid sm:grid-cols-2 sm:gap-16 sm:space-y-0 lg:max-w-5xl lg:grid-cols-3"
                                >
                                    {posInfographics.map((infographic, infographicIndex) => (
                                        <li key={infographicIndex}>
                                            <div className="space-y-6">
                                                {infographic.links.length <= 0 ?
                                                    <img className="mx-auto h-40 w-40 rounded-full xl:h-56 xl:w-56 object-cover" src={infographic.imageUrl} alt="" /> :
                                                    <PhotoProvider>
                                                        <PhotoView src={infographic.links[0].url}>
                                                            <img className="mx-auto h-40 w-40 rounded-full xl:h-56 xl:w-56 object-cover" src={infographic.imageUrl} alt="" />
                                                        </PhotoView>
                                                    </PhotoProvider>
                                                }
                                                <div className="space-y-2">
                                                    <div className="space-y-1 text-lg font-medium leading-6">
                                                        <h3>{infographic.name}</h3>
                                                        <p className="text-indigo-600">{infographic.role}</p>
                                                    </div>
                                                    <ul role="list" className="flex justify-center space-x-5">
                                                        {infographic.links.map((link, linkIndex) =>
                                                            <li key={linkIndex}>
                                                                <a
                                                                    href={link.type !== 'download' ? null : link.url}
                                                                    onClick={() => link.type !== 'show' ? null : imagePreview.show({ imageURL: link.url, name: infographic.name, fileInfo: link.fileInfo, informations: [], authors: [] })}
                                                                    target={link.type === 'download' ? '_blank' : null} className="text-gray-400 hover:text-gray-500">
                                                                    <span className="sr-only">
                                                                        {({
                                                                            download: 'Unduh',
                                                                            show: ({
                                                                                image: 'Gambar',
                                                                                pdf: 'PDF'
                                                                            }[link.fileType])
                                                                        }[link.type])}
                                                                    </span>
                                                                    {({

                                                                        show: <Icon.IconPhospor.MagnifyingGlass className="w-6 h-6" />,
                                                                        download: ({
                                                                            image: <Icon.IconPhospor.Image className="w-6 h-6" />,
                                                                            pdf: <Icon.IconPhospor.FilePdf className="w-6 h-6" />
                                                                        }[link.fileType])
                                                                    }[link.type])}
                                                                </a>
                                                            </li>
                                                        )}
                                                    </ul>
                                                </div>
                                            </div>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                            <div className="space-y-12">
                                <div className="space-y-5 sm:mx-auto sm:max-w-xl sm:space-y-4 lg:max-w-5xl">
                                    <h2 className="text-3xl font-bold tracking-tight sm:text-4xl">Infografis Standar Pelayanan</h2>
                                    <p className="text-xl text-gray-500">
                                        berikut semua infograsi yang tersedia terkait dengan Standar Pelayanan
                                    </p>
                                </div>
                                <ul
                                    role="list"
                                    className="mx-auto space-y-16 sm:grid sm:grid-cols-2 sm:gap-16 sm:space-y-0 lg:max-w-5xl lg:grid-cols-3"
                                >
                                    {serviceStandardInfographics.map((infographic, infographicIndex) => (
                                        <li key={infographicIndex}>
                                            <div className="space-y-6">
                                                {infographic.links.length <= 0 ?
                                                    <img className="mx-auto h-40 w-40 rounded-full xl:h-56 xl:w-56 object-cover" src={infographic.imageUrl} alt="" /> :
                                                    <PhotoProvider>
                                                        <PhotoView src={infographic.links[0].url}>
                                                            <img className="mx-auto h-40 w-40 rounded-full xl:h-56 xl:w-56 object-cover" src={infographic.imageUrl} alt="" />
                                                        </PhotoView>
                                                    </PhotoProvider>
                                                }
                                                <div className="space-y-2">
                                                    <div className="space-y-1 text-lg font-medium leading-6">
                                                        <h3>{infographic.name}</h3>
                                                        <p className="text-indigo-600">{infographic.role}</p>
                                                    </div>
                                                    <ul role="list" className="flex justify-center space-x-5">
                                                        {infographic.links.map((link, linkIndex) =>
                                                            <li key={linkIndex}>
                                                                <a
                                                                    href={link.type !== 'download' ? null : link.url}
                                                                    onClick={() => link.type !== 'show' ? null : imagePreview.show({ imageURL: link.url, name: infographic.name, fileInfo: link.fileInfo, informations: [], authors: [] })}
                                                                    target={link.type === 'download' ? '_blank' : null} className="text-gray-400 hover:text-gray-500">
                                                                    <span className="sr-only">
                                                                        {({
                                                                            download: 'Unduh',
                                                                            show: ({
                                                                                image: 'Gambar',
                                                                                pdf: 'PDF'
                                                                            }[link.fileType])
                                                                        }[link.type])}
                                                                    </span>
                                                                    {({

                                                                        show: <Icon.IconPhospor.MagnifyingGlass className="w-6 h-6" />,
                                                                        download: ({
                                                                            image: <Icon.IconPhospor.Image className="w-6 h-6" />,
                                                                            pdf: <Icon.IconPhospor.FilePdf className="w-6 h-6" />
                                                                        }[link.fileType])
                                                                    }[link.type])}
                                                                </a>
                                                            </li>
                                                        )}
                                                    </ul>
                                                </div>
                                            </div>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </section>
                </section>
                <section className="flex flex-col w-full">
                    <section className="max-w-7xl mx-auto relative px-10">
                        <div className="border-b-2 w-6 border-primary"></div>
                        <h2 className="text-4xl tracking-tight font-extrabold text-primary sm:text-5xl md:text-6xl">
                            Video
                        </h2>
                        <div className="mx-auto max-w-7xl py-12 px-6 lg:px-8 lg:py-24">
                            <div className="space-y-12">
                                <ul
                                    role="list"
                                    className="space-y-12 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:gap-y-12 sm:space-y-0 lg:grid-cols-3 lg:gap-x-8"
                                >
                                    {videos.map((video, videoIndex) => (
                                        <li key={videoIndex}>
                                            <a href={video.videoUrl} className="space-y-4">
                                                <div className="aspect-w-3 aspect-h-2">
                                                    <img className="rounded-lg object-cover shadow-lg" src={video.imageUrl} alt="" />
                                                </div>

                                                <div className="space-y-2">
                                                    <div className="space-y-1 text-lg font-medium leading-6">
                                                        <h3>{video.title}</h3>
                                                        <p className="text-indigo-600">{video.categogry}</p>
                                                    </div>
                                                </div>
                                            </a>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </section>
                </section>
            </div>
        </PaddedContainer>
    )
}