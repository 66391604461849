import { Fragment, useState } from 'react'
import { MagnifyingGlassIcon } from '@heroicons/react/20/solid'
import { FaceFrownIcon, GlobeAmericasIcon } from '@heroicons/react/24/outline'
import { Combobox, Dialog, Transition } from '@headlessui/react'
import Icon from 'Common/UI/Component/Icon'
import { cn } from 'Common/Utility/class-name'

import { useHotkeys } from 'react-hotkeys-hook'

const items = [
    { id: 'PAGE-1', name: 'Beranda', category: 'Halaman', url: `/` },
    { id: 'PAGE-2', name: 'Layanan', category: 'Halaman', url: `/layanan` },
    { id: 'PAGE-3', name: 'Informasi Publik', category: 'Halaman', url: `/informasi-publik` },
    { id: 'PAGE-4', name: 'Pengaduan', category: 'Halaman', url: `/pengaduan` },
    { id: 'PAGE-5', name: 'ZI-WBK', category: 'Halaman', url: `/zona-integritas` },
]

export default function Search() {
    const [query, setQuery] = useState('')
    const [open, setOpen] = useState(false)

    const filteredItems =
        query === ''
            ? []
            : items.filter((item) => {
                return item.name.toLowerCase().includes(query.toLowerCase())
            })

    const groups = filteredItems.reduce((groups, item) => {
        return { ...groups, [item.category]: [...(groups[item.category] || []), item] }
    }, {})

    useHotkeys('ctrl+shift+f', () => setOpen(true), [open])
    
    return (
        <div>
            <button onClick={() => setOpen(true)}>
                <Icon.IconPhospor.MagnifyingGlass className='h-5 w-auto text-primary ' />
            </button>
            <Transition.Root show={open} as={Fragment} afterLeave={() => setQuery('')} appear>
                <Dialog as="div" className="relative z-10" onClose={setOpen}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-gray-500 bg-opacity-25 transition-opacity" />
                    </Transition.Child>

                    <div className="fixed inset-0 z-10 overflow-y-auto p-4 sm:p-6 md:p-20 flex items-center justify-center">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <Dialog.Panel className="mx-96 w-full min-h-[670px] transform overflow-hidden rounded-xl bg-white shadow-2xl ring-1 ring-black ring-opacity-5 transition-all">
                                <Combobox onChange={(item) => (window.location = item.url)}>
                                    <div className="relative">
                                        <MagnifyingGlassIcon
                                            className="pointer-events-none absolute top-3.5 left-4 h-5 w-5 text-gray-400"
                                            aria-hidden="true"
                                        />
                                        <Combobox.Input
                                            className="h-12 w-full border-0 bg-transparent pl-11 pr-4 text-gray-800 placeholder-gray-400 focus:ring-0 sm:text-sm"
                                            placeholder="Search..."
                                            onChange={(event) => setQuery(event.target.value)}
                                        />
                                    </div>

                                    {query === '' && (
                                        <div className="border-t border-gray-100 py-14 px-6 text-center text-sm sm:px-14">
                                            <GlobeAmericasIcon className="mx-auto h-6 w-6 text-gray-400" aria-hidden="true" />
                                            <p className="mt-4 font-semibold text-gray-900">Cari konten</p>
                                            <p className="mt-2 text-gray-500">
                                                Ketik kata kunci yang berkaitan dengan konten
                                            </p>
                                        </div>
                                    )}

                                    {filteredItems.length > 0 && (
                                        <Combobox.Options static className=" scroll-pt-11 scroll-pb-2 space-y-2 overflow-y-auto pb-2 h-full">
                                            {Object.entries(groups).map(([category, items]) => (
                                                <li key={category}>
                                                    <h2 className="bg-gray-100 py-2.5 px-4 text-xs font-semibold text-gray-900">{category}</h2>
                                                    <ul className="mt-2 text-sm text-gray-800">
                                                        {items.map((item) => (
                                                            <Combobox.Option
                                                                key={item.id}
                                                                value={item}
                                                                className={({ active }) =>
                                                                    cn('cursor-default select-none px-4 py-2', active && 'bg-indigo-600 text-white')
                                                                }
                                                            >
                                                                <a href={item.url} target='_blank'>
                                                                    {item.name}
                                                                </a>
                                                            </Combobox.Option>
                                                        ))}
                                                    </ul>
                                                </li>
                                            ))}
                                        </Combobox.Options>
                                    )}

                                    {query !== '' && filteredItems.length === 0 && (
                                        <div className="border-t border-gray-100 py-14 px-6 text-center text-sm sm:px-14">
                                            <FaceFrownIcon className="mx-auto h-6 w-6 text-gray-400" aria-hidden="true" />
                                            <p className="mt-4 font-semibold text-gray-900">No results found</p>
                                            <p className="mt-2 text-gray-500">We couldn’t find anything with that term. Please try again.</p>
                                        </div>
                                    )}
                                </Combobox>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </Dialog>
            </Transition.Root>
        </div>
    )
}