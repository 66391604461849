
const SHORT_SIZES = ['B', 'KB', 'MB', 'GB', 'TB']
const LONG_SIZES = ['B', 'KB', 'MB', 'GB', 'TB']

const toHumanReadable = (bytes, options = {}) => {
    if (bytes) {
        const separator = options.separator || ' '
        const postFix = options.postFix || ''
        const sizes = options.sizes || SHORT_SIZES

        const i = Math.min(parseInt(Math.floor(Math.log(bytes) / Math.log(1024)).toString(), 10), sizes.length - 1);
        return `${(bytes / (1024 ** i)).toFixed(i ? 1 : 0)}${separator}${sizes[i]}${postFix}`;
    }
    return 'n/a';
}

export default { toHumanReadable }