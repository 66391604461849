
import { useDetailMyCredential } from "Core/Authentication/Credential/API/MyCredentialAPI";
import jwt_decode from "jwt-decode";
import { create } from "zustand";
import { persist, createJSONStorage } from 'zustand/middleware'

const KEY_AUTHENTICATION = 'authentication-key'

const ROLE_SUPER_ADMINISTRATOR = 'Super-Administrator'
const ROLE_EMPLOYEE = 'Employee'
/**
 * This represents some generic auth provider API, like Firebase.
 * @todo NEED TO CHANGE TO Zustand Store FOR FUCK SAKE
 */
const authenticationProvider = {
    in(token, callback) {
        localStorage.setItem(KEY_AUTHENTICATION, token)
        if (callback) {
            setTimeout(callback, 100);
        }
    },
    out(callback) {
        localStorage.removeItem(KEY_AUTHENTICATION)
        if (callback) {
            setTimeout(callback, 100);
        }
    },
    authenticated() {
        return this.token() !== null
    },
    token() {
        return localStorage.getItem(KEY_AUTHENTICATION)
    },
    role() {
        const token = this.token()
        if (token === null) {
            return null
        }
        const payload = jwt_decode(token)
        return payload.data.role
    },
    isRole(role) {
        const currentRole = this.role()
        if (Array.isArray(role)) {
            for (let index in role) {
                if (currentRole === role[index]) {
                    return true
                }
            }
        } else {
            if (currentRole === role) {
                return true
            }
        }
        return false
    },
};

const initialState = {
    token: null,
    user: null,
    role: null,
}

export const useAuthentication = create(persist(
    (set, get) => ({
        ...initialState,

        setToken: (value) => set(() => ({ token: value })),
        setUser: (value) => set(() => ({ user: value })),
        setRole: (value) => set(() => ({ role: value })),
        out: () => set(() => initialState),
        in: ({ token, user }) => set(() => ({ token, user })),
        isAuthenticated: () => get().user !== null

    }),
    {
        name: 'credential', // name of the item in the storage (must be unique)
        storage: createJSONStorage(() => sessionStorage), // (optional) by default, 'localStorage' is used
    }
))

export const AuthenticationState = {
    getToken: () => useAuthentication.getState().token,
    getRole: () => useAuthentication.getState().role,
}

export { authenticationProvider, KEY_AUTHENTICATION, ROLE_SUPER_ADMINISTRATOR, ROLE_EMPLOYEE };
