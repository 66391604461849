import { Disclosure, Menu } from "@headlessui/react";
import { useWindowScroll } from "@uidotdev/usehooks";
import Icon from "Common/UI/Component/Icon";
import Logo from "Common/UI/Component/Logo";
import { cn } from "Common/Utility/class-name";
import classNames from "classnames";
import { Fragment } from "react";
import { NavLink, Link } from "react-router-dom";
import Search from "./Search";

export default function NavigationBar() {

    const [{ y }, scrollTo] = useWindowScroll();

    const navigationDesktopLinkClassName = ({ isActive }) => classNames(isActive ?
        "bg-primary text-white font-bold inline-flex items-center py-1 px-4 rounded-xl text-lg" :
        "text-white font-semibold inline-flex items-center py-1 px-4 rounded-xl text-lg",
        "m-2 flex justify-center gap-2"
    )
    const navigationMobileLinkClassName = ({ isActive }) => classNames(isActive ?
        "bg-indigo-50 border-indigo-500 text-indigo-700 block pl-3 pr-4 py-2 border-l-4 text-base font-medium" :
        "border-transparent text-gray-600 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-800 block pl-3 pr-4 py-2 border-l-4 text-base font-medium"
    )

    const navigationDesktopSubLinkClassName = ({ isActive }) => classNames(isActive ?
        "bg-primary text-white font-bold inline-flex items-center py-1 px-4 rounded-xl text-lg" :
        "text-white font-semibold inline-flex items-center py-1 px-4 rounded-xl text-lg",
        "m-2"
    )

    return (
        <Disclosure as="nav" className=" fixed top-0 left-0 right-0 z-20">
            {({ open, close }) => (
                <>
                    <div className={cn("max-w-full mx-auto px-2 sm:px-4 lg:px-8", open && "bg-white")}>
                        <div className={classNames("relative flex justify-center transition-height", y >= 300 ? 'h-20' : 'h-28')}>
                            <div className={cn("absolute top-10 left-0 px-2 lg:px-0 transition-height", y >= 300 ? 'top-3' : 'top-10')}>
                                <div className="flex-shrink-0 flex items-center gap-x-3 ">
                                    <Link to="/">
                                        <Logo.CompanyLogo className="h-10 w-auto" />
                                    </Link>
                                    <Link to="/">
                                        <Logo.DepartmentLogo className="h-9 w-auto" />
                                    </Link>
                                </div>
                            </div>
                            <div className="flex px-2 lg:px-0 flex-col justify-center">
                                <div className="hidden lg:ml-6 lg:flex lg:space-x-8 ">
                                    <div className={cn("flex bg-primary/30 px-10 rounded-full", y >= 300 ? 'bg-primary/50' : '')}>
                                        <div className="flex items-center">
                                            <NavLink to="/" className={navigationDesktopLinkClassName}>
                                                Beranda
                                            </NavLink>
                                        </div>
                                        <div className="flex items-center">
                                            <NavLink to="/profil" className={navigationDesktopLinkClassName}>
                                                Profil
                                            </NavLink>
                                        </div>
                                        <div className="flex items-center">
                                            <Menu className="relative" as="div">
                                                {({ open }) => (
                                                    <Fragment>
                                                        <NavLink to="/layanan" >
                                                            {({ isActive }) => (
                                                                <Menu.Button className={navigationDesktopLinkClassName({ isActive })}>
                                                                    Layanan {open ? <Icon.IconPhospor.CaretCircleUp /> : <Icon.IconPhospor.CaretCircleDown />}
                                                                </Menu.Button>
                                                            )}
                                                        </NavLink>
                                                        <Menu.Items className={cn("absolute top-13 bg-primary/30 rounded-b-xl", y >= 300 ? 'bg-primary/50' : '')}>
                                                            <Menu.Item>
                                                                <a href="https://mitreka.kemdikbud.go.id" target="_blank" className={navigationDesktopSubLinkClassName({ isActive: false })}>
                                                                    Indeks Kemitraan
                                                                </a>
                                                            </Menu.Item>
                                                            <Menu.Item>
                                                                <a href="https://penyelarasan.mitrasdudi.id/" target="_blank" className={navigationDesktopSubLinkClassName({ isActive: false })}>
                                                                    Penyelarasan
                                                                </a>
                                                            </Menu.Item>
                                                            <Menu.Item>
                                                                <a href="https://tracervokasi.kemdikbud.go.id/" target="_blank" className={navigationDesktopSubLinkClassName({ isActive: false })}>
                                                                    Penelusuran Lulusan
                                                                </a>
                                                            </Menu.Item>
                                                        </Menu.Items>
                                                    </Fragment>
                                                )}
                                            </Menu>
                                        </div>
                                        <div className="flex items-center">
                                            <NavLink to="/informasi-publik" className={navigationDesktopLinkClassName}>
                                                Informasi Publik
                                            </NavLink>
                                        </div>
                                        <div className="flex items-center">
                                            <Menu className="relative" as="div">
                                                {({ open }) => (
                                                    <Fragment>
                                                        <NavLink to="/pengaduan" >
                                                            {({ isActive }) => (
                                                                <Menu.Button className={navigationDesktopLinkClassName({ isActive })}>
                                                                    Pengaduan {open ? <Icon.IconPhospor.CaretCircleUp /> : <Icon.IconPhospor.CaretCircleDown />}
                                                                </Menu.Button>
                                                            )}
                                                        </NavLink>
                                                        <Menu.Items className={cn("absolute top-17 bg-primary/30 rounded-b-xl", y >= 300 ? 'bg-primary/50' : '')}>
                                                            <Menu.Item>
                                                                <a href="https://form.mitrasdudi.id/fill/2" target="_blank" className={navigationDesktopSubLinkClassName({ isActive: false })}>
                                                                    Pengaduan Masyarakat dan Layanan Publik
                                                                </a>
                                                            </Menu.Item>
                                                            <Menu.Item>
                                                                <a href="https://www.lapor.go.id/" target="_blank" className={navigationDesktopSubLinkClassName({ isActive: false })}>
                                                                    SP4N-LAPOR
                                                                </a>
                                                            </Menu.Item>
                                                            <Menu.Item>
                                                                <a href="https://wbs.mitrasdudi.id/" target="_blank" className={navigationDesktopSubLinkClassName({ isActive: false })}>
                                                                    WBS
                                                                </a>
                                                            </Menu.Item>
                                                        </Menu.Items>
                                                    </Fragment>
                                                )}
                                            </Menu>
                                        </div>
                                        <div className="flex items-center">
                                            <NavLink to="/agenda" className={navigationDesktopLinkClassName}>
                                                Agenda
                                            </NavLink>
                                        </div>
                                    </div>

                                </div>
                            </div>

                            <div className="hidden md:block absolute top-10 right-0 px-2 lg:px-0">
                                <Search />
                            </div>

                            <div className="absolute top-10 right-0 px-2 lg:px-0">
                                <div className="flex items-center lg:hidden">
                                    {/* Mobile menu button */}
                                    <Disclosure.Button className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                                        <span className="sr-only">Open main menu</span>
                                        {open ? (
                                            <Icon.IconSolid.XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                                        ) : (
                                            <Icon.IconSolid.Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                                        )}
                                    </Disclosure.Button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <Disclosure.Panel className="lg:hidden bg-white">
                        <div className="pt-2 pb-3 space-y-1">
                            <NavLink to="/" onClick={close} className={navigationMobileLinkClassName}>
                                Beranda
                            </NavLink>
                            <NavLink to="/profil" onClick={close} className={navigationMobileLinkClassName}>
                                Profil
                            </NavLink>
                            <NavLink to="/layanan" onClick={close} className={navigationMobileLinkClassName}>
                                Layanan
                            </NavLink>
                            <NavLink to="/informasi-publik" onClick={close} className={navigationMobileLinkClassName}>
                                Informasi Publik
                            </NavLink>
                            <NavLink to="/pengaduan" onClick={close} className={navigationMobileLinkClassName}>
                                Pengaduan
                            </NavLink>
                            <NavLink to="/kontak" onClick={close} className={navigationMobileLinkClassName}>
                                Kontak
                            </NavLink>
                        </div>
                    </Disclosure.Panel>
                </>
            )
            }
        </Disclosure >
    )
}