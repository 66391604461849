import React from 'react'
import {
    Dialog,
    DialogHeader,
    DialogBody,
    DialogFooter,
    Avatar,
    Typography,
} from "@material-tailwind/react";
import moment from 'moment';

export const ItemPartnerCompany = React.memo(function ({ partnerCompany }) {
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => {
        if (valid(partnerCompany)) {
            setOpen((cur) => !cur)
        }
    };

    const valid = (partnerCompany) =>
        partnerCompany.post?.author !== undefined &&
        partnerCompany.post?.title !== undefined && partnerCompany.post?.title !== '' &&
        partnerCompany.post?.postDate !== undefined && partnerCompany.post?.postDate !== ''

    return (
        <div>
            <button type='button' onClick={handleOpen} target="_blank" className="col-span-1 flex justify-center md:col-span-2 lg:col-span-1 group">
                <img className="h-14 group-hover:scale-150 transform transition duration-y grayscale group-hover:grayscale-0" src={partnerCompany.companyLogo?.url} alt="Tuple" />
            </button>

            <Dialog size="xl" open={open} handler={handleOpen}>
                <DialogHeader className="justify-between">
                    <div className="flex items-center gap-3">
                        <Avatar
                            size="sm"
                            variant="circular"
                            alt={partnerCompany.post?.author?.name}
                            src={partnerCompany.post?.author?.avatar?.url}
                        />
                        <div className="-mt-px flex flex-col">
                            <Typography
                                variant="small"
                                color="blue-gray"
                                className="font-medium"
                            >
                                {partnerCompany.post?.author?.name || ''}
                            </Typography>
                            <Typography
                                variant="small"
                                color="gray"
                                className="text-xs font-normal"
                            >
                                Publisher
                            </Typography>
                        </div>
                    </div>
                    <div className="flex items-center gap-2">

                    </div>
                </DialogHeader>
                <DialogBody>
                    <img
                        alt="nature"
                        className="h-[48rem] w-full rounded-lg object-cover object-center"
                        src={partnerCompany.post?.image?.url}
                    />
                </DialogBody>
                <DialogFooter className="justify-between">
                    <div className="flex items-center gap-16">
                        <div>
                            <Typography variant="small" color="gray" className="font-normal">
                                Tanggal
                            </Typography>
                            <Typography color="blue-gray" className="font-medium">
                                {partnerCompany.post?.postDate ?
                                    moment(partnerCompany.post?.postDate, 'YYYY-MM-DD').format('D MMM, YYYY') :
                                    '-' || ''
                                }
                            </Typography>
                        </div>
                        <div>
                            <Typography variant="small" color="gray" className="font-normal">
                                Judul
                            </Typography>
                            <Typography color="blue-gray" className="font-medium">
                                {partnerCompany.post?.title || ''}
                            </Typography>
                        </div>
                    </div>

                </DialogFooter>
            </Dialog>
        </div>
    )
})