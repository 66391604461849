import { Fragment, useRef, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import Icon from '../Component/Icon'
import { create } from 'zustand'
import { shallow } from 'zustand/shallow'
import React from 'react'
import { PhotoProvider, PhotoView } from 'react-photo-view';
import 'react-photo-view/dist/react-photo-view.css';
import Byte from 'Common/Utility/Translator/Byte'
import FileExtension from 'Common/Utility/Translator/FileExtension'
import Avatar from '../Component/Avatar'
import { toInitial } from 'Common/Utility/formatter'

const initialState = {
  isShow: false,
  name: 'IMAGE.PNG',
  fileInfo: null,
  imageURL: null,
  informations: [],
  authors: []
}

export const useImagePreviewDialogStore = create((set) => ({
  ...initialState,
  reset: () => set(() => (initialState)),
  setShow: (value) => set(() => ({ isShow: value })),
  show: ({ imageURL, name, fileInfo, informations, authors }) => {
    let update = { isShow: true, imageURL, name, fileInfo, informations: informations || [], authors: authors || [] }
    return set(() => update)
  },
}))

export function ImagePreviewDialog() {
  const isShow = useImagePreviewDialogStore(state => state.isShow)
  const { imageURL, name, fileInfo, informations, authors } = useImagePreviewDialogStore(state => ({
    imageURL: state.imageURL, fileInfo: state.fileInfo, name: state.name,
    informations: state.informations || [], authors: state.authors || []
  }), shallow)

  const size = fileInfo?.size || 0
  const extension = fileInfo?.fileExtension

  const reset = useImagePreviewDialogStore(state => state.reset)
  const setShow = useImagePreviewDialogStore(state => state.setShow)

  const onClose = () => {
    setShow(false)
    reset()
  }


  return (
    <Transition.Root show={isShow} as={Fragment}>
      <Dialog as="div" className="fixed inset-0 overflow-hidden z-20" onClose={onClose}>
        <div className="absolute inset-0 overflow-hidden">
          <Transition.Child
            as={Fragment}
            enter="ease-in-out duration-500"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in-out duration-500"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>
          <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
            <Transition.Child
              as={Fragment}
              enter="transform transition ease-in-out duration-500 sm:duration-700"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <div className="pointer-events-auto relative w-[36rem]">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-500"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-500"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute top-0 left-0 -ml-8 flex pt-4 pr-2 sm:-ml-10 sm:pr-4">
                    <button
                      type="button"
                      className="rounded-md text-gray-300 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                      onClick={onClose}
                    >
                      <span className="sr-only">Close panel</span>
                      <Icon.IconSolid.XMarkIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>
                </Transition.Child>
                <div className="h-full overflow-y-auto bg-white p-8">
                  <div className="space-y-6 pb-16">
                    <div>
                      <div className="aspect-w-10 aspect-h-7 block w-full overflow-hidden rounded-lg">
                        <PhotoProvider>
                          <PhotoView src={imageURL}>
                            <img
                              src={imageURL}
                              alt=""
                              className="object-cover"
                            />
                          </PhotoView>
                        </PhotoProvider>
                      </div>
                      <div className="mt-4 flex items-start justify-between">
                        <div>
                          <h2 className="text-lg font-medium text-gray-900 text-ellipsis">
                            <span className="sr-only">Details for </span><span className=''>{name}</span>
                          </h2>
                          <p className="text-sm font-medium text-gray-500">{Byte.toHumanReadable(size)}</p>
                        </div>
                        <button
                          type="button"
                          className="ml-4 flex h-8 w-8 items-center justify-center rounded-full bg-white text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                        >


                          {React.createElement(FileExtension.toIcon(extension), { className: 'w-6 h-6' })}
                          <span className="sr-only">Favorite</span>
                        </button>
                      </div>
                    </div>
                    <div>
                      <h3 className="font-medium text-gray-900">Information</h3>
                      <dl className="mt-2 divide-y divide-gray-200 border-t border-b border-gray-200">
                        {informations.map(({ label, value }) =>
                          <div key={label} className="flex justify-between py-3 gap-x-2 text-sm font-medium">
                            <dt className="text-gray-500">{label}</dt>
                            <dd className="text-gray-900 text-left">{value}</dd>
                          </div>
                        )}
                      </dl>
                    </div>
                    {authors.length <= 0 ? null :
                      <div>
                        <h3 className="font-medium text-gray-900">Author</h3>
                        <ul role="list" className="mt-2 divide-y divide-gray-200 border-t border-b border-gray-200">
                          {authors.map(author => (
                            <li className="flex items-center justify-between py-3">
                              <div className="flex items-center">
                                <Avatar.Single size='normal' image={author.avatar?.fullURL} shape="circle" label={toInitial(author.name)} />
                                <p className="ml-4 text-sm font-medium text-gray-900">{author.name}</p>
                              </div>
                              <a
                                href={`mailto:${author.email}`}
                                className="ml-6 rounded-md bg-white text-sm font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                              >
                                Contact<span className="sr-only"> {author.email}</span>
                              </a>
                            </li>
                          ))}
                        </ul>
                      </div>
                    }

                    <div className="flex">
                      <a
                        href={imageURL}
                        target='_blank'
                        download={true}
                        className="flex-1 rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2" rel="noreferrer"
                      >
                        Download
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}